import { Service } from "../types";
import {
  V1ProjectServiceResource,
  V1ProjectSubserviceResource,
  V1Relationships,
} from "@generated";
import API from "../../../../utils/API/API";

interface Resource {
  data: { id: string; type: "project-resources" };
}

export const formatResource = (service): Resource | undefined => {
  if (!service?.resource?.id || service?.resource?.id === -1) return;
  const resourceId = service?.resource?.id?.toString()?.split("-").pop();
  const resourceType = service?.resource?.id?.split("-").slice(0, -1).join("-");

  return {
    data: {
      id:
        resourceType === "project-resources"
          ? resourceId
          : String(service?.resource?.id),
      type: "project-resources",
    },
  };
};

export const handleCreateProjectServices = async (
  services: Service[],
  serviceType: "professional_services" | "managed_services",
  project_id: number,
  accountSlug: string,
  authorizationCode: string,
  handleFailAlert: (msg: string) => void
) => {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  try {
    for (const service of services) {
      const data: V1ProjectServiceResource = {
        type: "project-services",
        //@ts-ignore
        attributes: {
          name: service?.name || "",
          quantity: Number(service?.qty || 1),
          "service-type": serviceType,
        },
        relationships: {
          project: { data: { id: project_id, type: "projects" } },
          "project-resource": formatResource(service),
          service: {
            data: { id: Number(service.id), type: "services" },
          },
        },
      };

      // POST the service
      const serviceResponse = await API.Post(
        `${apiHost}/${accountSlug}/v1/project-services`,
        { data },
        authorizationCode
      );

      //@ts-ignore
      const projectServiceId = serviceResponse?.data?.data?.id;

      if (projectServiceId) {
        const projectService = await API.Get(
          `${apiHost}/${accountSlug}/v1/project-services/${projectServiceId}?include=project-subservices,project-subservices.subservice,service,service.subservices`,
          authorizationCode
        );

        ///PATCH project-subservices with changed subservice data
        if (service.subservices?.length) {
          const changedSubservices = service.subservices.filter(
            (sub) => sub.isChanged
          );

          for (const subservice of changedSubservices) {
            const projectSubservice = projectService?.data?.included?.find(
              (item) =>
                item?.type === "project-subservices" &&
                item?.relationships?.subservice?.data?.id === subservice.id
            );

            if (projectSubservice) {
              const data: V1ProjectSubserviceResource = {
                id: Number(projectSubservice.id),
                type: "project-subservices",
                //@ts-ignore
                attributes: {
                  name: subservice?.name || "",
                  quantity: Number(subservice?.qty || 1),
                },
                relationships: {
                  "project-resource": formatResource(subservice),
                  "project-service": {
                    data: {
                      id: projectServiceId,
                      type: "project-services",
                    },
                  },
                },
              };

              // PATCH each subservice
              try {
                await API.Patch(
                  `${apiHost}/${accountSlug}/v1/project-subservices/${projectSubservice.id}`,
                  { data },
                  authorizationCode
                );
              } catch (err) {
                handleFailAlert(
                  "Something went wrong applying some subservices to the project"
                );
                return false;
              }
            }
          }
        }
      }
    }

    return true;
  } catch (err) {
    handleFailAlert(
      "Something went wrong applying these services to the project"
    );
    return false;
  }
};
