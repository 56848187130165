import { useGetProductQuery } from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { Product } from "../types";

const useGetProduct = (productId: number) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading } = useGetProductQuery({
    slug: accountSlug,
    id: productId,
  });
  

  let product: Product | undefined = undefined;
  let customError: string | null = null;

  if (data?.data && data.data.id) {
    try {
      product = {
        id: data.data?.id || 0,
        type: data.data.type,
        attributes: {
          name: data.data.attributes?.name || "",
          description: data.data.attributes?.description || "",
          sku: data.data.attributes?.sku || "",
          intProdID: data.data.attributes?.["product-id"] || "",
          mfgPartNum: data.data.attributes?.["manufacturer-part-number"] || "",
          units: data.data.attributes?.["unit-of-measure"] || "",
          category: data.data.attributes?.category || "",
          subcategory: data.data.attributes?.subcategory || "",
          unitPrice: data.data.attributes?.["unit-price"] || 0,
          unitCost: data.data.attributes?.["unit-cost"] || 0,
          listPrice: data.data.attributes?.["list-price"] || 0,
        },
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return { product, error: error || customError, isLoading };
};

export default useGetProduct;
