import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import {
  Card,
  Form,
  Button,
  Row,
  Col,
  Tab,
  Tabs,
  ListGroup,
} from "react-bootstrap";
import useGetAccount from "../../Teams/common/api/useGetAccount";
import FormTextField from "@components/Forms/FormTextField";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { handleAlert } from "@utils/helperFunctions";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { RootState } from "@reducers/rootReducer";
import {
  useCreateProductMutation,
  useGetAccountQuery,
  useUpdateProductMutation,
  useWhoAmIQuery,
  V1ProductResource,
} from "@generated";
import { Product } from "./types";
import "../style.css";
import VersionHistory from "@components/VersionHistory";

interface ProductInfoProps {
  mode: "create" | "edit";
  product: Product;
  setProductAttributes: (product) => void;
  refetch?: () => void;
  onSubmit: (nextAction: "edit" | "new") => void;
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  mode,
  product,
  setProductAttributes,
  onSubmit,
  refetch = () => {},
}) => {
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const navigate = useNavigate();
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { account } = useGetAccount();

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showFailAlert, setShowFailAlert] = useState(false);

  const [createProduct] = useCreateProductMutation();
  const [updateProduct] = useUpdateProductMutation();

  const [currentTab, setCurrentTab] = useState(1);
  const [activeTab, setActiveTab] = useState<string>("1");

  const formatProductData = (
    data: Product,
    accountId: number
  ): V1ProductResource => {
    let product: V1ProductResource = {
      id: Number(data.id),
      type: "products",
      attributes: {
        name: data.attributes.name,
        description: data.attributes?.description || "",
        sku: data.attributes?.sku || "",
        "product-id": data.attributes?.intProdID || "",
        "manufacturer-part-number": data.attributes?.mfgPartNum || "",
        "unit-of-measure": data.attributes?.units || "",
        category: data.attributes?.category || "",
        subcategory: data.attributes?.subcategory || "",
        "unit-price": data.attributes?.unitPrice || 0,
        "unit-cost": data.attributes?.unitCost || 0,
        "list-price": data.attributes?.listPrice || 0,
      },
      relationships: {
        account: {
          data: {
            id: Number(accountId),
            type: "accounts",
          },
        },
      },
    };

    return product;
  };

  const handleFailResponse = (msg: string) => {
    setLoading(false);
    setErrorMessage(msg || "Something went wrong.");
    setShowFailAlert(true);
  };

  const handleUpdateProduct = () => {
    setLoading(true);

    if (!account?.id) {
      handleFailResponse("Account must exist to create a product.");
      return;
    }

    const data = formatProductData(product, account.id);

    updateProduct({ slug: accountSlug, id: Number(data.id), body: { data } })
      .unwrap()
      .then((res) => {
        const id = res?.data?.id;
        const updatedProduct = res?.data;
        if (updatedProduct) {
          setProductAttributes((product) => ({
            ...product,
            attributes: {
              ...product.attributes,
              ...updatedProduct.attributes,
            },
          }));
          setSuccessMessage("Product updated successfully!");
          setShowSuccessAlert(true);
          setTimeout(() => {
            refetch();
          }, 200);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        console.error("Error details:", JSON.stringify(error?.data, null, 2));

        handleFailResponse(
          error?.data?.errors?.[0]?.detail || "An error occured."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveProduct = () => {
    setLoading(true);

    if (!account?.id) {
      handleFailResponse("Account must exist to create a product.");
      return;
    }

    const data = formatProductData(product, account.id);
    delete data.id;

    const newProductData: V1ProductResource = {
      type: "products",
      attributes: {
        name: product.attributes.name,
        description: product.attributes?.description || "",
        sku: product.attributes?.sku || "",
        "product-id": product.attributes?.intProdID || "",
        "manufacturer-part-number": product.attributes?.mfgPartNum || "",
        "unit-of-measure": product.attributes?.units || "",
        category: product.attributes?.category || "",
        subcategory: product.attributes?.subcategory || "",
        "unit-price": product.attributes?.unitPrice || 0,
        "unit-cost": product.attributes?.unitCost || 0,
        "list-price": product.attributes?.listPrice || 0,
      },
      relationships: {
        account: {
          data: {
            id: account.id,
            type: "accounts",
          },
        },
      },
    };

    createProduct({ slug: accountSlug, body: { data: newProductData } })
      .unwrap()
      .then((res) => {
        const id = res?.data?.id;
        if (id) {
          navigate(`/admin/materials/edit/${id}`);
        } else {
          handleFailResponse("Failed to create product.");
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        handleFailResponse(
          error?.data?.errors?.[0]?.detail || "An error occurred."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Get Account for version history
  const { data: accountId, isLoading } = useGetAccountQuery({
    slug: accountSlug,
    include: ["resources", "phases"],
  });

  // Get permissions for current user
  const { data: currentUser } = useWhoAmIQuery();
  const permission = currentUser?.data?.attributes?.privileges;
  const auditLogPermission = permission?.find(
    (privilege) => privilege.privilege === "settings.audit_logs"
  )?.["access-level"];

  const renderProductForm = () => (
    <Form>
      <Card.Title
        style={{ color: "#1c2655", padding: "8px 0px", fontWeight: 700 }}
      >
        Identifier
      </Card.Title>
      <Row>
        {Object.entries(product.attributes)
          .filter(([key]) => key === "name" || key === "description")
          .map(([key, value]) => {
            const labelMap: Record<string, string> = {
              name: "*Name",
              description: "Description",
            };

            return (
              <Col key={key}>
                <FormTextField
                  key={key}
                  id={key}
                  label={labelMap[key]}
                  value={value}
                  placeholder={`Enter ${labelMap[key]}`}
                  onChange={(e) =>
                    setProductAttributes({
                      ...product,
                      attributes: {
                        ...product.attributes,
                        [key]: e.target.value,
                      },
                    })
                  }
                  required={false}
                  readOnly={false}
                />
              </Col>
            );
          })}
      </Row>
      <Row>
        {["mfgPartNum", "intProdID", "sku"].map((key) => (
          <Col key={key}>
            <FormTextField
              id={key}
              label={
                {
                  mfgPartNum: "Manufacturer Part Number",
                  intProdID: "Internal Product ID",
                  sku: "Vendor SKU",
                }[key]
              }
              value={
                product.attributes[key as keyof typeof product.attributes] || ""
              }
              placeholder={`Enter ${
                {
                  mfgPartNum: "Manufacturer Part Number",
                  intProdID: "Internal Product ID",
                  sku: "Vendor SKU",
                }[key]
              }`}
              onChange={(e) =>
                setProductAttributes({
                  ...product,
                  attributes: { ...product.attributes, [key]: e.target.value },
                })
              }
              required={false}
              readOnly={false}
            />
          </Col>
        ))}
      </Row>
      <hr />
      <Card.Title
        style={{ color: "#1c2655", padding: "8px 0px", fontWeight: 700 }}
      >
        Classification
      </Card.Title>
      <Row>
        {Object.entries(product.attributes)
          .filter(([key]) => key === "category" || key === "subcategory")
          .map(([key, value]) => {
            const labelMap: Record<string, string> = {
              category: "Category",
              subcategory: "Subcategory",
            };

            return (
              <Col key={key}>
                <FormTextField
                  key={key}
                  id={key}
                  label={labelMap[key]}
                  value={value}
                  placeholder={`Enter ${labelMap[key]}`}
                  onChange={(e) =>
                    setProductAttributes({
                      ...product,
                      attributes: {
                        ...product.attributes,
                        [key]: e.target.value,
                      },
                    })
                  }
                  required={false}
                  readOnly={false}
                />
              </Col>
            );
          })}
      </Row>
      <hr />
      <Card.Title
        style={{ color: "#1c2655", padding: "8px 0px", fontWeight: 700 }}
      >
        Price
      </Card.Title>
      <Row className="priceTabs">
        <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
          <Col sm={3} className="priceToggleTabsCol">
            <ListGroup>
              <ListGroup.Item className="toggleTabs-Price" action href="#link1">
                <svg
                  className="activeTabSvg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="7.5" fill="#418172" />
                  <circle
                    cx="8"
                    cy="8"
                    r="7.5"
                    stroke="white"
                    strokeOpacity="0.2"
                  />
                  <circle cx="8" cy="8" r="7.5" stroke="#E3E4E5" />
                  <circle cx="8" cy="8" r="6" stroke="white" strokeWidth="2" />
                </svg>
                <svg
                  className="inactiveTabSvg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="7.5" stroke="#E3E4E5" />
                </svg>
                Manage Unit Price
              </ListGroup.Item>
              <ListGroup.Item className="toggleTabs-Price" action href="#link2">
                <svg
                  className="activeTabSvg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="7.5" fill="#418172" />
                  <circle
                    cx="8"
                    cy="8"
                    r="7.5"
                    stroke="white"
                    strokeOpacity="0.2"
                  />
                  <circle cx="8" cy="8" r="7.5" stroke="#E3E4E5" />
                  <circle cx="8" cy="8" r="6" stroke="white" strokeWidth="2" />
                </svg>
                <svg
                  className="inactiveTabSvg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="7.5" stroke="#E3E4E5" />
                </svg>
                Manage List Price
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Tab.Content>
            <Tab.Pane className="priceTabBackground" eventKey="#link1">
              <div className="priceTabContent">
                {Object.entries(product.attributes)
                  .filter(([key]) => key === "unitPrice" || key === "unitCost")
                  .map(([key, value]) => {
                    const labelMap: Record<string, string> = {
                      unitPrice: "Unit Price",
                      unitCost: "Unit Cost",
                    };

                    return (
                      <Col key={key}>
                        <FormTextField
                          key={key}
                          id={key}
                          label={labelMap[key]}
                          value={value}
                          placeholder={`Enter ${labelMap[key]}`}
                          onChange={(e) =>
                            setProductAttributes({
                              ...product,
                              attributes: {
                                ...product.attributes,
                                [key]: e.target.value,
                              },
                            })
                          }
                          required={false}
                          readOnly={false}
                        />
                      </Col>
                    );
                  })}
              </div>
            </Tab.Pane>
            <Tab.Pane className="priceTabBackground-list" eventKey="#link2">
              <Col>
                <FormTextField
                  id="listPrice"
                  label="List Price"
                  value={product.attributes.listPrice}
                  placeholder="Enter List Price"
                  onChange={(e) =>
                    setProductAttributes({
                      ...product,
                      attributes: {
                        ...product.attributes,
                        listPrice: e.target.value,
                      },
                    })
                  }
                  required={true}
                  readOnly={false}
                />
              </Col>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Row>
    </Form>
  );

  return (
    <>
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <Card
        style={{
          padding: "16px",
          borderRadius: "4px",
          border: "1px solid var(--gray-3-stroke, #D7D7D7)",
          background: "var(--white, #FFF)",
          boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Card.Header
          style={{
            backgroundColor: "#fff",
            lineHeight: "40px",
            display: "block",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              className="accordianTitle"
              style={{ height: "40px", margin: "0", alignContent: "center" }}
            >
              <Button
                className="cancelButton"
                variant="secondary"
                onClick={() =>
                  window.location.replace(`${appHost}/admin/materials`)
                }
              >
                Cancel
              </Button>
              {mode === "edit" ? `Edit Product` : "Add Product"}
            </div>

            <div className="actionBtns">
              <Button
                className="regButton"
                onClick={() =>
                  mode === "edit" ? handleUpdateProduct() : handleSaveProduct()
                }
                disabled={loading}
                variant="primary"
              >
                {loading ? <SmallSpinner /> : "Save"}
              </Button>
            </div>
          </div>
          <hr />
        </Card.Header>
        <Tabs
          defaultActiveKey="productDetails"
          id="productDetails"
          className="mb-3"
        >
          <Tab
            className="prodDetailTab"
            eventKey="productDetails"
            title="Product Details"
          >
            <hr />
            <Card.Body style={{ padding: "0px 16px 16px 16px" }}>
              {renderProductForm()}
            </Card.Body>
          </Tab>

          <Tab
            className="varRateTab"
            eventKey="variableRates"
            title="Variable Rates"
          ></Tab>
          <Tab
            className="acctCodesTab"
            eventKey="accountingCodes"
            title="Accounting Codes"
          ></Tab>
          {auditLogPermission === "none" ? null : (
            <Tab
              className="prodVersionsTab"
              eventKey="productVersions"
              title="Product Versions"
            >
              <hr />
              <Card.Body style={{ padding: "0px 16px 16px 16px" }}>
                <div style={{ marginTop: "20px" }}>
                  {isLoading ? (
                    <SmallSpinner />
                  ) : (
                    <VersionHistory
                      filter={{
                        "context-type": "Material",
                        "context-id": product?.id.toString(),
                      }}
                      permission={auditLogPermission === "manage"}
                      account={accountId?.data}
                    />
                  )}
                </div>
              </Card.Body>
            </Tab>
          )}
        </Tabs>
      </Card>
    </>
  );
};

export default ProductInfo;
