import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useGetProduct from "../common/api/useGetProduct";
import ProductInfo from "../common";
import { Product } from "../common/types/Product";

const Edit = () => {
  const { id } = useParams();
  const productId = Number(id) || 0;
  const {
    product: existingProduct,
    isLoading,
    error,
  } = useGetProduct(productId);
  const [productAttributes, setProductAttributes] = useState<Product>({
    id: 0,
    type: "products",
    attributes: {
      name: "",
      description: "",
      sku: "",
      intProdID: "",
      mfgPartNum: "",
      units: "",
      category: "",
      subcategory: "",
      unitPrice: 0,
      unitCost: 0,
      listPrice: 0,
    },
  });
  useEffect(() => {
    if (existingProduct?.attributes && productId !== 0) {
      setProductAttributes((product) => {
        if (product.id !== 0) return product;

        return {
          id: existingProduct.id,
          type: "products",
          attributes: {
            name: existingProduct.attributes.name || "",
            description: existingProduct.attributes?.description || "",
            sku: existingProduct.attributes?.sku || "",
            intProdID: existingProduct.attributes?.intProdID || "",
            mfgPartNum: existingProduct.attributes?.mfgPartNum || "",
            units: existingProduct.attributes?.units || "",
            category: existingProduct.attributes?.category || "",
            subcategory: existingProduct.attributes?.subcategory || "",
            unitPrice: existingProduct.attributes?.unitPrice || 0,
            unitCost: existingProduct.attributes?.unitCost || 0,
            listPrice: existingProduct.attributes?.listPrice || 0,
          },
        };
      });
    }
  }, [existingProduct, productId]);

  function onSubmit() {}

  return (
    <ProductInfo
      mode="edit"
      product={productAttributes}
      setProductAttributes={setProductAttributes}
      onSubmit={onSubmit}
    />
  );
};

export default Edit;
