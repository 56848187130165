import React from "react";
import ScopeStackModal from "../ScopeStackModal/ScopeStackModal";
import { FileUploadDragAndDrop } from "@components/FormsV2/FileUploadDragAndDrop";
import fileToBase64 from "@utils/fileToBase64";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";
import { useCreateDataImportMutation, V1DataImportResource } from "@generated";

function DataImportModal({
  account,
  projectId,
  modalTitle,
  importType,
  contextType,
  contextId,
  importInstructions,
  setErrorMessage,
  setLoadingIndicator,
  setShowFailAlert,
  setShowDataImportModal,
  showDataImportModal = false,
}) {
  const [createDataImport] = useCreateDataImportMutation();
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  const handleFileSelect = async (file: File) => {
    if (file) {
      const encodedFile = await fileToBase64(file);

      if (!account || !account.data?.attributes?.slug) return;
      const importData: V1DataImportResource = {
        type: "data-imports",
        attributes: {
          "import-type": importType,
          "context-type": contextType,
          "context-id": contextId.toString(),
          "data-file": encodedFile,
        },
        relationships: {
          account: {
            data: {
              type: "accounts",
              id: account.data?.attributes["account-id"],
            },
          },
        },
      };

      if (encodedFile) {
        setLoadingIndicator(true);
        createDataImport({
          slug: account?.attributes?.slug,
          body: { data: importData },
        })
          .then((res) => {
            const importId = res["data"].data.id;
            setShowDataImportModal(false);
            window.location.replace(
              `${appHost}/projects/${projectId}/project_imports/${importId}/edit`
            );
          })
          .catch((error) => {
            setLoadingIndicator(false);
            setErrorMessage(
              formatUnprocessibleResponse(error, importType)
            );
            setShowFailAlert(true);
          });
      }
    }
  }

  return (
    <>
      <ScopeStackModal
        modalTitle={modalTitle}
        modalBody={
          <div>
            <div className="instructionalText seafoamText bold">
              {importInstructions}
            </div>
            <FileUploadDragAndDrop handleFileSelect={handleFileSelect} />
          </div>
        }
        button1Text="Cancel"
        handleButton1Click={() => setShowDataImportModal(false)}
        button2Text={null}
        handleButton2Click={() => null}
        show={showDataImportModal}
        handleClose={() => setShowDataImportModal(false)}
      />
    </>
  );
}

export default DataImportModal;
