import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useGetQuestionnaire, useGetSurvey } from "../api";
import "../style/style.css";
import {
  useUpdateSurveyMutation,
  V1SurveyResource,
  useCalculateSurveyRecommendationsMutation,
  UpdateSurveyApiResponse,
  CalculateSurveyRecommendationsApiResponse,
  useGetProjectByIdQuery,
} from "@generated";
import { useSearchParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { Survey } from "../types";
import useOauth from "@utils/customHooks/useOauth";
import { Service } from "../../Projects/ServicesV2/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-solid-svg-icons";
import { renderResourceOptions } from "@utils/helperFunctions";
import {
  useListResourceRates,
  useListProjectResources,
  useListResources,
} from "../../Projects/ServicesV2/api";
import Select from "react-select";
import useGetAccount from "../../Account/EditAccount/api/useGetAccount";
import { Question } from "../types/Question";
import { ServicePreview } from "@components/PreviewSlideouts";
import newSurvey from "../../../pages/Projects/CreateProjectMspa/Services/Questionnaire/api/newSurvey";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import QuestionnaireForm from "@components/TakeSurvey/QuestionnaireForm/QuestionnaireForm";
import SurveyRecommendations from "@components/TakeSurvey/SurveyRecommendations";
import { applySurvey as handleApply, formatRecommendations } from "../common";

const New = ({ project_id }) => {
  const [authorizationCode] = useOauth();
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const location = useLocation();
  //boolean as to whether user came from project > surveys OR project > PS services
  const fromProjectServices = location?.state?.from?.includes("take");

  const [searchParams] = useSearchParams();
  const questionnaireId = searchParams.get("questionnaire");
  const [surveyId, setSurveyId] = useState<number | undefined>(undefined);

  const [questions, setQuestions] = useState<Question[] | null>(null);
  const [recommendations, setRecommendations] =
    useState<Survey["recommendations"] | null>(null);

  const [step, setStep] = useState<1 | 2>(1);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [serviceToPreview, setServiceToPreview] = useState<Service>({
    id: "0",
    name: "",
  });
  const [slideoutOpen, setSlideoutOpen] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(true);

  const { questionnaire, questionnaireLoading } =
    useGetQuestionnaire(questionnaireId);

  const { survey, surveyLoading, surveyRefetch } = useGetSurvey({
    id: surveyId || 0,
  });
  const [updateSurvey] = useUpdateSurveyMutation();
  const [calculate] = useCalculateSurveyRecommendationsMutation();

  const { data: project } = useGetProjectByIdQuery({
    slug: accountSlug,
    id: Number(project_id),
    include: ["rate-table"],
  });

  const { data: account, isLoading: accountLoading } =
    useGetAccount(accountSlug);

  const rateTableId = project?.data?.relationships?.["rate-table"]?.data?.id;
  const resourceRatesEnabled =
    account?.attributes?.settings?.project_resource_rates;
  const { resourceRates } = useListResourceRates(rateTableId);
  const { resources } = useListResources();
  const { projectResources } = useListProjectResources(project_id);

  useEffect(() => {
    if (!questionnaireLoading && questionnaire?.questions && !questions) {
      setQuestions(questionnaire.questions);
    }
  }, [questionnaireLoading]);

  useEffect(() => {
    if (account?.id && questionnaire?.id && authorizationCode) {
      newSurvey({
        questionnaire: {
          id: questionnaireId,
          type: "questionnaires",
          attributes: { name: questionnaire?.name || "" },
        },
        account: {
          id: account?.attributes?.accountId,
          slug: accountSlug,
          authorizationCode,
        },
        projectId: project_id,
        surveyId: undefined,
        responses: undefined,
      }).then(async (res) => {
        const id = res?.data?.id;
        if (id) {
          setSurveyId(id);
          await surveyRefetch();
          setLoading(false);
        }
      });
    }
  }, [accountLoading, questionnaireLoading, authorizationCode]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (unsavedChanges) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [unsavedChanges]);

  const handleFailResponse = (msg) => {
    setErrorMessage(msg || "Something went wrong");
    setShowFailAlert(true);
    setLoading(false);
  };

  const handleCalculate = async () => {
    if (!surveyId || !questionnaireId) return;

    const missingRequiredQuestion = questions?.some(
      (q) =>
        q.required &&
        !q.answer &&
        !q?.["select-options"]?.find((o) => o.default)
    );

    if (missingRequiredQuestion) {
      handleFailResponse("Please complete all required fields");
      return;
    }

    setLoading(true);

    // Patch survey.responses
    const responses = questions?.map((q) => {
      const defaultValue = q?.["select-options"]?.find((o) => o.default);
      return {
        "question-id": q?.["question-id"],
        question: q?.question,
        //@ts-ignore
        answer: q?.answer || defaultValue?.value,
        "survey-response-id": q?.["survey-response-id"],
      };
    });

    try {
      const data: V1SurveyResource = {
        id: Number(surveyId),
        type: "surveys",
        attributes: {
          name: survey?.name || "",
          responses,
        },
        relationships: {
          questionnaire: {
            data: { id: Number(questionnaireId), type: "questionnaires" },
          },
        },
      };

      const updatedSurvey = (await updateSurvey({
        slug: accountSlug,
        id: Number(surveyId),
        body: { data },
      })) as UpdateSurveyApiResponse;

      // Calculate
      if (updatedSurvey?.data) {
        const updatedResponses =
          //@ts-ignore
          updatedSurvey?.data?.data?.attributes?.responses?.map((r) => {
            const question = questions?.find(
              (q) => String(q["question-id"]) == String(r["question-id"])
            );
            return {
              ...question,
              "survey-response-id": r["survey-response-id"],
            };
          });

        if (updatedResponses) setQuestions(updatedResponses);

        const calculated = (await calculate({
          slug: accountSlug,
          id: Number(surveyId),
        })) as CalculateSurveyRecommendationsApiResponse;

        if (calculated?.data) {
          const recommended =
            //@ts-ignore
            calculated.data?.data?.attributes?.recommendations;

          if (recommended?.length) {
            const formattedRecommendations: Survey["recommendations"] =
              await formatRecommendations(
                recommended,
                authorizationCode,
                accountSlug,
                handleFailResponse
              );

            setRecommendations(formattedRecommendations);
            setStep(2);
            setLoading(false);
            surveyRefetch();
          } else {
            handleFailResponse("");
          }
        }

        //@ts-ignore
        const error = calculated?.error?.data?.errors?.[0]?.detail;
        if (error) handleFailResponse(error);
      }

      //@ts-ignore
      const error = updatedSurvey?.error?.data?.errors?.[0]?.detail;
      if (error) handleFailResponse(error);
    } catch (err) {
      handleFailResponse("");
    }
  };

  const reviewColumns = [
    {
      id: "service name",
      header: () => "Service Name",
      cell(item) {
        return item.id !== "noRow" ? (
          <div className="serviceName">
            <Form.Control type="text" value={item.name} disabled={true} />
            {item.type === "services" && (
              <div className="previewService review">
                <FontAwesomeIcon
                  icon={faEye}
                  onClick={() => {
                    setServiceToPreview(item);
                    setSlideoutOpen(true);
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          item.name
        );
      },
    },
    {
      id: "total hours",
      header: () => "Total Hours",
      cell(item) {
        return (
          item.id !== "noRow" && (
            <Form.Control
              type="text"
              value={item.hours || ""}
              disabled={true}
            />
          )
        );
      },
    },
    {
      id: "qty",
      header: () => "QTY",
      cell(item) {
        return (
          item.id !== "noRow" && (
            <Form.Control
              type="number"
              min="0"
              step="1"
              defaultValue={item.quantity || 1}
              onBlur={(e) => {
                item = {
                  ...item,
                  quantity: e.target.value,
                };
                const updated = (recommendations || []).map((s) => {
                  if (item.type === "subservices") {
                    return {
                      ...s,
                      subservices:
                        s.subservices?.map((sub) =>
                          sub.id === item.id
                            ? { ...item, isChanged: true }
                            : sub
                        ) || [],
                    };
                  }

                  return s.id === item.id ? item : s;
                });
                setRecommendations(updated);
              }}
            />
          )
        );
      },
    },
    {
      id: "resource",
      header: () => "Resource",
      cell(item) {
        const service =
          item?.type === "subservices"
            ? recommendations?.find((s) => s.id === item?.serviceId)
            : undefined;

        //Use the defined resource if it exists, otherwise, if it is a subservice, default to service resource
        const resource =
          item?.resource || service?.resource
            ? {
                label: item?.resource?.name || service?.resource?.name,
                value: item?.resource?.id || service?.resource?.id,
              }
            : undefined;

        return (
          item?.id !== "noRow" &&
          item?.serviceType == "professional_services" && (
            <Select
              className="resourceDropdown"
              value={resource}
              options={renderResourceOptions(
                projectResources,
                resourceRatesEnabled ? resourceRates : resources,
                []
              )}
              onChange={(e) => {
                if (e) {
                  item = {
                    ...item,
                    resource: { id: e.value, name: e.label },
                  };
                  const updated = (recommendations || []).map((s) => {
                    if (item.type === "subservices") {
                      return {
                        ...s,
                        subservices:
                          s.subservices?.map((sub) =>
                            sub.id === item.id
                              ? { ...item, isChanged: true }
                              : sub
                          ) || [],
                      };
                    }

                    return s.id === item.id ? item : s;
                  });
                  setRecommendations(updated);
                }
              }}
            />
          )
        );
      },
    },
  ];

  const showSpinner =
    loading ||
    accountLoading ||
    surveyLoading ||
    questionnaireLoading ||
    !questions ||
    !questionnaire ||
    !surveyId;

  return (
    <div className="servicesV2 applySurvey">
      {showSpinner ? (
        <ScopeStackSpinner />
      ) : (
        <>
          {" "}
          {handleAlert(
            showFailAlert,
            errorMessage,
            setShowFailAlert,
            "warning",
            ToastAlert
          )}
          <div className="actionBtns">
            <div className="leftHeader">
              {" "}
              <Button
                className="button cancelButton"
                onClick={(e) => {
                  if (step === 1) {
                    window.location.replace(
                      `${appHost}/projects/${project_id}/project_surveys`
                    );
                  } else {
                    setRecommendations(null);
                    setStep(1);
                  }
                }}
              >
                Back
              </Button>
              <div className="servicesH1">
                Add Services from {questionnaire?.name || "Survey"}
              </div>
            </div>
            <Button
              className={`button`}
              onClick={() => {
                if (step === 1) {
                  handleCalculate();
                } else {
                  setUnsavedChanges(false);
                  handleApply(
                    recommendations!,
                    surveyId,
                    project_id,
                    { slug: accountSlug, code: authorizationCode },
                    handleFailResponse,
                    fromProjectServices
                  );
                }
              }}
              disabled={!questions?.length || showSpinner}
            >
              {step === 1 ? "Calculate" : "Apply to Project"}
            </Button>
          </div>
          <div className="servicesH2">
            {step === 1
              ? "1. Complete survey questionnaire."
              : "2. Review & apply recommendations."}
          </div>
          {step === 1 ? (
            <QuestionnaireForm
              questionnaire={questionnaire}
              questions={questions}
              setQuestions={setQuestions}
            />
          ) : (
            <SurveyRecommendations
              columns={reviewColumns}
              recommendations={recommendations || []}
              calculations={survey?.calculations || []}
              responses={survey?.responses || []}
            />
          )}
          <ServicePreview
            service={serviceToPreview}
            slideoutOpen={slideoutOpen}
            setSlideoutOpen={setSlideoutOpen}
            isManagedService={
              serviceToPreview?.serviceType === "managed_services"
            }
          />
        </>
      )}
    </div>
  );
};

export default New;
