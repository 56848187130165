import React, { useEffect, useState, useRef } from "react";
import { ServicesTable } from "../../../Services/ServicesTable/ServicesTable";
import { Form, Row, Col } from "react-bootstrap";
import { ColumnDef } from "@tanstack/react-table";
import { Service, Subservice } from "../common/types";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Option } from "../Show/types";
import useListLanguageFields from "../common/api/useListLanguageFields";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import { Column } from "@components/EditableDataTable/types/column";

function ServiceLanguage({ servicePassed, setServicePassed, permission }) {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [selectedLanguageField, setSelectedLanguageField] = useState({
    label: "Service Description",
    value: "service-description",
  });
  const [service, setService] = useState<Service[]>([{ ...servicePassed }]);
  const [fieldOptions, setFieldOptions] = useState<Option[]>([]);
  const { fields } = useListLanguageFields();

  useEffect(() => {
    const classList = document.getElementById(
      `multiCollapse${service[0]?.id}`
    )?.classList;
    if (!classList?.contains("show")) {
      classList?.add("show");
    }
  }, []);

  const getLanguageFieldValue = (item) => {
    return selectedLanguageField.value === "service-description"
      ? item[selectedLanguageField.value]
      : item.languages?.[selectedLanguageField.value];
  };

  useEffect(() => {
    if (fields?.length && !fieldOptions.length) {
      setFieldOptions([
        { label: "Service Description", value: "service-description" },
        ...fields,
      ]);
    }
  }, [fields]);

  const getColumns = (serviceType) => {
    const columns: Column[] = [
      {
        header() {
          return `${serviceType} Name`;
        },
        cell(service: Service) {
          return service.id === "noRow" ? (
            <div>{service.name}</div>
          ) : (
            <Form.Control
              type="text"
              defaultValue={service.name}
              disabled
              readOnly
              className={`${serviceType.toLowerCase()}LanguageServiceNames`}
            />
          );
        },
      },
    ];
    if (
      serviceType === "Service" ||
      (serviceType === "Subservice" && service[0]?.subservices?.length > 0)
    ) {
      columns.push({
        id: "servicelanguageitem",
        header() {
          return selectedLanguageField.label;
        },
        cell(service: Service) {
          return fieldOptions?.map((language, index) => (
            <Form.Control
              id={service.id}
              key={language.value}
              as="textarea"
              type="text"
              rows={3}
              style={
                language?.label !== selectedLanguageField?.label
                  ? { display: "none" }
                  : { display: "block" }
              }
              defaultValue={
                language?.label == selectedLanguageField?.label
                  ? getLanguageFieldValue(service)
                  : null
              }
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                e.stopPropagation();
                if (getLanguageFieldValue(service) !== e.target.value) {
                  let item = { ...service };
                  if (selectedLanguageField.value === "service-description") {
                    item["service-description"] = e.target.value;
                  } else {
                    item.languages = {
                      ...item.languages,
                      [selectedLanguageField.value]: e.target.value,
                    };
                  }
                  service = { ...item, isChanged: true };
                  setServiceData(service);
                }
              }}
            />
          ));
        },
      });
    }
    return columns;
  };

  const onUpdateServices = (subservices) => {
    const updatedService = service.map((s) => {
      s.subservices = subservices;
      return s;
    });
    setService(updatedService);
    setServicePassed(updatedService[0]);
  };

  const setServiceData = (item) => {
    const services = service ? service : [];
    let newlyUpdatedService = [...services].map((obj) => {
      if (obj?.subservices && obj.subservices.length > 0) {
        obj.subservices.forEach((sub) => {
          if (sub.id == item.id) {
            const newSubservices =
              obj?.subservices?.filter(
                (subservice) => subservice.id !== item.id
              ) || [];
            obj.subservices = [...newSubservices, item];
          }
        });
      }
      if (obj.id === item.id) {
        return item;
      } else {
        return obj;
      }
    });
    setService(newlyUpdatedService);
    setServicePassed(newlyUpdatedService[0]);
  };

  return (
    <Form>
      <Row style={{ marginTop: "20px" }}>
        <Col>
          <FormFieldLabel label="Guidance Language" className="" />
          <Form.Control
            as="textarea"
            rows={3}
            value={service[0]?.guidance}
            onChange={(e) => {
              setService([{ ...service[0], guidance: e.target.value }]);
              setServicePassed({ ...service[0], guidance: e.target.value });
            }}
            placeholder="Enter guidance language for the service"
            disabled={permission !== "manage"}
          />
        </Col>
      </Row>
      <Row
        style={{
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <Col style={{ marginLeft: "16px" }} sm={7}>
          <Row style={{ alignItems: "center" }}>
            <Col className="instructionalText" sm={4}>
              Show / edit content for:
            </Col>
            <Col>
              <div style={{ width: "300px", marginLeft: "-40px" }}>
                <Select
                  onChange={(e) => setSelectedLanguageField(e!)}
                  className="basic-single"
                  value={selectedLanguageField}
                  options={fieldOptions}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <ServicesTable
        servicesPassed={service}
        onUpdateServices={onUpdateServices}
        serviceColumns={getColumns("Service")}
        subserviceColumns={getColumns("Subservice")}
        addSubservice={() => null}
        deleteService={() => null}
        hasSettings={false}
        hasDragAndDrop={true}
        isCollapsible={true}
        startUncollapsed={true}
      />
    </Form>
  );
}

export default ServiceLanguage;
