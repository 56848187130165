import { Survey } from "../types";
import getService from "../../Projects/CreateProjectMspa/Services/Questionnaire/api/getService";

const formatRecommendations = async (
  recommendations,
  authorizationCode,
  accountSlug,
  handleFailResponse
) => {
  const formattedRecommendations: Survey["recommendations"] = [];

  await Promise.all(
    recommendations.map(async (recommendation) => {
      if (recommendation["item_type"] === "Task") {
        const { data, included, error } = await getService({
          account: { authorizationCode, slug: accountSlug },
          serviceId: recommendation["item_id"],
        });

        if (error) {
          handleFailResponse("");
          return;
        }

        const resourceId =
          recommendation?.["resource_id"] ||
          data?.relationships?.resource?.data?.id;
        const resource = resourceId
          ? included?.find((i) => i.type === "resources" && i.id == resourceId)
          : undefined;

        const phaseId = data?.relationships?.phase?.data?.id;
        const phase = phaseId
          ? included?.find((i) => i.type === "phases" && i.id == phaseId)
          : undefined;

        const categoryId = data?.relationships?.["service-category"]?.data?.id;
        const category = categoryId
          ? included?.find(
              (i) => i.type === "service-categories" && i.id == categoryId
            )
          : undefined;

        //All subservices associated with service
        const subservices =
          data?.relationships?.subservices?.data?.map((subservice) => {
            const sub = included?.find(
              (item) => item.type === "subservices" && item.id == subservice.id
            );
            const subResource = included?.find(
              (i) =>
                i.type === "resources" &&
                i.id == sub?.relationships?.resource?.data?.id
            );

            return {
              id: sub?.id || "",
              type: "subservices",
              name: sub?.attributes?.name || "",
              qty: sub?.attributes?.quantity || "",
              resource:
                subResource || resource
                  ? {
                      id: `resources-${subResource?.id || resource?.id}`,
                      name:
                        subResource?.attributes?.name ||
                        resource?.attributes?.name ||
                        "",
                    }
                  : undefined,
              hours: subservice?.attributes?.["suggested-hours"],
              quantity: subservice?.attributes?.["miniumum-qty"],
              active: sub?.attributes?.active,
            };
          }) || [];

        //Recommendations for subservices
        const refinements =
          recommendation?.refinements?.map((refinement) => {
            const subservice = included?.find(
              (i) => i.type === "subservices" && i.id == refinement["item_id"]
            );
            return {
              ...subservice,
              id: refinement["item_id"],
              qty: refinement.quantity,
            };
          }) || [];

        const products = data?.relationships?.products?.data?.map((p) => {
          const product = included?.find(
            (item) => item.type === "products" && item.id == p.id
          );
          return {
            id: product?.id || 0,
            name: product?.attributes?.name || "",
            mpn: product?.attributes?.["manufacturer-part-number"] || "",
            price: product?.attributes?.["unit-price"] || "",
          };
        });

        formattedRecommendations.push({
          id: recommendation.id,
          type: "services",
          serviceType:
            data?.attributes?.["service-type"] || "professional_services",
          category:
            category?.attributes?.["nested-name"] || category?.attributes?.name,
          name: data?.attributes?.name,
          quantity:
            recommendation?.quantity || data?.attributes?.["minimum-quantity"],
          hours: data?.attributes?.["suggested-hours"],
          resource: resource
            ? {
                id: `resources-${resource?.id}`,
                name: resource?.attributes?.name,
              }
            : undefined,
          phase:
            data?.attributes?.["service-type"] === "professional_services"
              ? {
                  id: phase?.id || -1,
                  name: phase?.attributes?.name || "",
                  position: phase?.attributes?.position || 999999,
                }
              : {
                  id: -1,
                  name: "Managed Services",
                  position: 999999,
                },
          refinements,
          subservices: subservices.filter((sub) => sub.active),
          products,
          status: recommendation?.status || "",
        });
      }
    })
  );

  return formattedRecommendations;
};
export default formatRecommendations;
