import React, { useState, useEffect, useRef } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import BackButton from "@components/Buttons/BackButton/BackButton";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import CardHeader from "@components/CardHeader/CardHeader";
import MultipleLineField from "@components/Forms/MulitpleLineField";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import API from "@API";
import useOauth from "@utils/customHooks/useOauth";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";
import useUnloadWarning from "@utils/customHooks/useUnloadWarning";

function CustomerSummary({ account_slug, project_id, minimizedSummary }) {
  const [projectStatus, setProjectStatus] = useState("");
  const [executiveSummary, setExecutiveSummary] = useState("");
  const [solutionSummary, setSolutionSummary] = useState("");
  const [ourResponsibilities, setOurResponsibilities] = useState("");
  const [customerResponsibilities, setCustomerResponsibilities] = useState("");
  const [outOfScope, setOutOfScope] = useState("");
  const [submitUrl, setSubmitUrl] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [fieldLabels, setFieldLabels] = useState({
    executive_summary: "Executive Summary",
    solution_summary: "Solution Summary",
    our_responsibilities: "Our Responsibilities",
    client_responsibilities: "Customer Responsibilities",
    out_of_scope: "Out of Scope",
  });
  const [authorizationCode] = useOauth();

  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [isLoading, setIsLoading] = useState(true);
  const [isFormDirty, setFormDirty] = useState(false);

  const toolbarItems = [
    ['heading', 'bold', 'italic'],
    ['ul', 'ol', 'indent', 'outdent'],
    ['table', 'link'],
  ];
  const execSummaryRef = useRef(null);
  const solnSummaryRef = useRef(null);
  const ourResponsibilitiesRef = useRef(null);
  const clientResponsibilitiesRef = useRef(null);
  const outOfScopeRef = useRef(null)

  useUnloadWarning(isFormDirty);

  useEffect(() => {
    if (authorizationCode != "") {
      API.Get(`${apiHost}/v1/accounts/${account_slug}`, authorizationCode).then(
        (response) => {
          const data = response.data.data;
          setFieldLabels(data.attributes["field-labels"]);
        }
      );

      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        if (data.attributes.status !== null)
          setProjectStatus(data.attributes.status);
        if (data.attributes["executive-summary"] !== null)
          setExecutiveSummary(data.attributes["executive-summary"]);
        if (data.attributes["solution-summary"])
          setSolutionSummary(data.attributes["solution-summary"]);
        if (data.attributes["our-responsibilities"] !== null)
          setOurResponsibilities(data.attributes["our-responsibilities"]);
        if (data.attributes["client-responsibilities"] !== null)
          setCustomerResponsibilities(
            data.attributes["client-responsibilities"]
          );
        if (data.attributes["out-of-scope"] !== null)
          setOutOfScope(data.attributes["out-of-scope"]);
        if (data.links.self !== null) setSubmitUrl(data.links.self);
        setIsLoading(false);
      });
    }
  }, [authorizationCode]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormDirty(false);
    const data = {
      data: {
        type: "projects",
        id: project_id,
        attributes: {
          "executive-summary": executiveSummary,
          "solution-summary": solutionSummary,
          "our-responsibilities": ourResponsibilities,
          "client-responsibilities": customerResponsibilities,
          "out-of-scope": outOfScope,
        },
      },
    };

    API.Patch(submitUrl, data, authorizationCode)
      .then((response) => {
        if (response.status === 200) {
          setShowSuccessAlert(true);
          setTimeout(() => {
            setShowSuccessAlert(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "summary"));
        setShowFailAlert(true);
      });
  };

  const handleSuccessAlert = () => {
    if (showSuccessAlert === true) {
      return (
        <DismissibleAlert
          variant="info"
          onClose={() => setShowSuccessAlert(false)}
          text="Project Outcomes updated"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };
  const handleFormChange = (event) => {
    setFormDirty(true);
  };

  const handleExecSummaryBlur = () => {
    setExecutiveSummary(execSummaryRef.current.editorInst.getMarkdown());
  }

  const handleSolnSummaryBlur = () => {
    setSolutionSummary(solnSummaryRef.current.editorInst.getMarkdown());
  }

  const handleOurResponsibilitiesBlur = () => {
    setOurResponsibilities(ourResponsibilitiesRef.current.editorInst.getMarkdown());
  }

  const handleClientResponsibilitiesBlur = () => {
    setCustomerResponsibilities(clientResponsibilitiesRef.current.editorInst.getMarkdown());
  }

  const handleOutOfScopeBlur = () => {
    setOutOfScope(outOfScopeRef.current.editorInst.getMarkdown());
  }

  return (
    <>
      {handleSuccessAlert()}
      {handleFailAlert()}
      <Card className="whiteBg details">
        <Card.Body>
          <Card.Header className="cardHeaderOverride">
            <div className="headerContainer">
              <div className="actionBtns">
                <h2>
                  <span className="text24">Solution Summary and Responsibilites</span>
                </h2>
              </div>
              { projectStatus === "building" && (
                <div className="actionBtns">
                  <Button
                    className="btnSeafoam"
                    type="button"
                    onClick={(e) => {
                      handleSubmit(e)
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </Card.Header>
          {isLoading ? (
            <ScopeStackSpinner />
          ) : (
            <Form onChange={handleFormChange}>
              <Row>
                <Col style={{ paddingLeft: '2em', paddingRight: '2em' }}>
                  <label className={'form-label'}>{fieldLabels["executive_summary"]}</label>
                  <Editor
                    initialValue={executiveSummary}
                    initialEditType='wysiwyg'
                    useCommandShortcut={true}
                    hideModeSwitch={true}
                    height={'200px'}
                    style={{ height: '200px', }}
                    ref={execSummaryRef}
                    toolbarItems={toolbarItems}
                    onBlur={handleExecSummaryBlur}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingLeft: '2em', paddingRight: '2em' }}>
                  <label className={'form-label'} style={{ paddingTop: '12px' }}>{fieldLabels["solution_summary"]}</label>
                  <Editor
                    initialValue={solutionSummary}
                    initialEditType='wysiwyg'
                    useCommandShortcut={true}
                    hideModeSwitch={true}
                    height={'200px'}
                    style={{ height: '200px', }}
                    toolbarItems={toolbarItems}
                    ref={solnSummaryRef}
                    onBlur={handleSolnSummaryBlur}
                  />
                </Col>
              </Row>
              {!minimizedSummary && (
                <>
                  <Row>
                    <Col style={{ paddingLeft: '2em', paddingRight: '2em' }}>
                      <label className={'form-label'} style={{ paddingTop: '12px' }}>{fieldLabels["our_responsibilities"]}</label>
                      <Editor
                        initialValue={ourResponsibilities}
                        initialEditType='wysiwyg'
                        useCommandShortcut={true}
                        hideModeSwitch={true}
                        height={'200px'}
                        style={{ height: '200px', }}
                        toolbarItems={toolbarItems}
                        ref={ourResponsibilitiesRef}
                        onBlur={handleOurResponsibilitiesBlur}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ paddingLeft: '2em', paddingRight: '2em' }}>
                      <label className={'form-label'} style={{ paddingTop: '12px' }}>{fieldLabels["client_responsibilities"]}</label>
                      <Editor
                        initialValue={customerResponsibilities}
                        initialEditType='wysiwyg'
                        useCommandShortcut={true}
                        hideModeSwitch={true}
                        height={'200px'}
                        style={{ height: '200px', }}
                        toolbarItems={toolbarItems}
                        ref={clientResponsibilitiesRef}
                        onBlur={handleClientResponsibilitiesBlur}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ paddingLeft: '2em', paddingRight: '2em' }}>
                      <label className={'form-label'} style={{ paddingTop: '12px' }}>{fieldLabels["out_of_scope"]}</label>
                      <Editor
                        initialValue={outOfScope}
                        initialEditType='wysiwyg'
                        useCommandShortcut={true}
                        hideModeSwitch={true}
                        height={'200px'}
                        style={{ height: '200px', }}
                        toolbarItems={toolbarItems}
                        ref={outOfScopeRef}
                        onBlur={handleOutOfScopeBlur}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default CustomerSummary;
