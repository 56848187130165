import { TextField, Select2V2 } from "@components/FormsV2";
import RequiredReactSelect from "@components/FormsV2/RequiredReactSelect/RequiredReactSelect";
import { Row, Col, Button, Form } from "react-bootstrap";
import {
  useListPhasesForAccount,
  useListServiceCategoriesForAccount,
} from "../../Show/api";
import { useEffect, useState, useRef } from "react";
import { Option } from "../../Show/types";
import { FormNumberFieldV2 } from "@components/FormsV2/FormNumberFieldV2";
import { ServicesTable } from "../../../../Services/ServicesTable/ServicesTable";
import { useDeleteSubserviceMutation } from "@generated";
import { Subservice, Service, LOE } from "../types";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import AdvancedServicePricing from "./AdvancedServicePricing/AdvancedServicePricing";
import Select from "react-select";
import ProjectVariablesV2 from "../../../../ProjectVariables/ProjectVariablesV2";
import { ProjectVariable } from "../types/ProjectVariable";
import { Column } from "@components/EditableDataTable/types/column";

interface Props {
  service: Service;
  setService: (service) => void;
  isManagedService: boolean;
  permission: string;
  editing: boolean;
  resourceOptions: Option[];
  refetch: () => void;
  setResetData?: (boolean) => void;
  changes?: boolean;
  allProjectVariables?: ProjectVariable[];
}

const General = ({
  service,
  setService,
  isManagedService,
  permission,
  editing,
  resourceOptions,
  refetch,
  setResetData,
  changes,
  allProjectVariables,
}: Props) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  const { phases } = useListPhasesForAccount();
  const [phaseOptions, setPhaseOptions] = useState<Option[]>([]);

  const { categories } = useListServiceCategoriesForAccount([]);
  const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);

  const frequencyOptions: Option[] = [
    { label: "Monthly", value: "monthly" },
    { label: "Quarterly", value: "quarterly" },
    { label: "Yearly", value: "yearly" },
  ];
  const [deleteSubserviceMutation] = useDeleteSubserviceMutation();

  const [subserviceToDelete, setSubserviceToDelete] =
    useState<Subservice | null>(null);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [advancedPricingItem, setAdvancedPricingItem] =
    useState<{
      id: string;
      type: "service" | "subservice";
    } | null>(null);
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    "Something went wrong. Your changes could not be saved at this time."
  );
  const [showAdvancedPricingSlideout, setShowAdvancedPricingSlideout] =
    useState<boolean>(false);
  // const [focusedId, setFocusedId] = useState<string | null>(null);

  useEffect(() => {
    if (phases.length && !phaseOptions.length) {
      setPhaseOptions(phases);
    }
  }, [phases]);

  useEffect(() => {
    if (JSON.stringify(categories) !== JSON.stringify(categoryOptions)) {
      setCategoryOptions(categories);
    }
  }, [categories]);

  const addSubservice = () => {
    setService({
      ...service,
      subservices: [
        ...service.subservices,
        {
          id: `new-${Math.floor(Math.random() * 100)}`,
          name: "",
          quantity: 1,
          hours: "",
          resource: "",
          position: Number(service.subservices?.length || 0) + 1,
        },
      ],
    });
  };

  const deleteSubservice = (selectedService) => {
    if (selectedService.id.includes("new")) {
      setService({
        ...service,
        subservices: service.subservices.filter(
          (s) => s.id !== selectedService.id
        ),
      });
    } else {
      setSubserviceToDelete(selectedService);
      setShowConfirmationModal(true);
    }
  };

  const handleFailResponse = (msg) => {
    setErrorMessage(msg || "Something went wrong.");
    setShowFailAlert(true);
  };

  const confirmDelete = () => {
    setShowConfirmationModal(false);
    if (subserviceToDelete) {
      deleteSubserviceMutation({
        slug: accountSlug,
        id: Number(subserviceToDelete?.id),
      })
        .then((res) => {
          setSuccessMessage("Subservice deleted");
          setShowSuccessAlert(true);
          setLoading(false);
          refetch();
        })
        .catch((err) => {
          handleFailResponse(err?.data?.errors?.[0]?.detail);
          setShowFailAlert(true);
          setLoading(false);
          refetch();
        });
    }
  };

  const updateSubserviceData = (item) => {
    let updated = service.subservices.map((subservice) => {
      if (subservice.id === item.id) {
        return item;
      } else {
        return subservice;
      }
    });
    setService({ ...service, subservices: updated });
  };

  const resourceList = [
    { value: -1, label: "Select a Resource" },
    ...resourceOptions,
  ];

  const subserviceColumns: Column[] = [
    {
      header() {
        return "Subservice Name";
      },
      cell(subservice: any) {
        return subservice.id === "noRow" ? (
          <div>{subservice.phase?.label}</div>
        ) : (
          <Form.Control
            type="text"
            defaultValue={subservice.name}
            onBlur={(e) => {
              updateSubserviceData({
                ...subservice,
                name: e.target.value,
                isChanged: true,
              });
            }}
          />
        );
      },
    },
    {
      header() {
        return "QTY";
      },
      cell(subservice: Subservice) {
        return subservice.id === "noRow" ? (
          ""
        ) : (
          <Form.Control
            type="number"
            min="0"
            step="1"
            defaultValue={subservice.quantity}
            // onFocus={(e) => {
            //   setFocusedId(subservice.id + "-qty");
            //   var val = e.target.value;
            //   e.target.value = "";
            //   e.target.value = val;
            // }}
            // autoFocus={focusedId === subservice.id + "-qty"}
            onBlur={(e) => {
              // setFocusedId(null);
              subservice = {
                ...subservice,
                quantity: Number(e.target.value),
                isChanged: true,
              };
              updateSubserviceData(subservice);
            }}
            onChange={(e) => {
              e.stopPropagation();
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        );
      },
    },
    {
      header() {
        return "Unit Hrs";
      },
      cell(subservice: any) {
        return subservice.id === "noRow" ? (
          ""
        ) : (
          <Form.Control
            type="number"
            min="0"
            step="0.01"
            defaultValue={subservice.hours}
            // onFocus={(e) => {
            //   setFocusedId(subservice.id + "-hrs");
            //   var val = e.target.value;
            //   e.target.value = "";
            //   e.target.value = val;
            // }}
            // autoFocus={focusedId === subservice.id + "-hrs"}
            onBlur={(e) => {
              // setFocusedId(null);
              subservice = {
                ...subservice,
                hours: Number(e.target.value),
                isChanged: true,
              };
              updateSubserviceData(subservice);
            }}
            onChange={(e) => {
              e.stopPropagation();
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        );
      },
    },
    {
      header() {
        return "Resource";
      },
      cell(subservice: Subservice) {
        const item = subservice;
        return item.id === "noRow" ? (
          ""
        ) : (
          <div
            style={{ width: "-webkit-fill-available" }}
            className={
              item.hasAdvancedPricing ? "subserviceAdvancedPricing" : ""
            }
          >
            <Select
              value={
                item?.resource
                  ? {
                      label: resourceList.find((r) => r.value == item?.resource)
                        ?.label,
                      value: item?.resource,
                    }
                  : subservice?.resource
                  ? {
                      label: resourceList.find(
                        (r) => r.value == subservice?.resource
                      )?.label,
                      value: subservice?.resource,
                    }
                  : resourceList[0]
              }
              onChange={(e) => {
                updateSubserviceData({
                  ...item,
                  resource: e?.value,
                  isChanged: true,
                });
              }}
              // onFocus={() => setFocusedId(null)}
              options={resourceList}
            />
            {item.hasAdvancedPricing && (
              <div className="advancedPricing subservice">
                <strong>Advanced Cost & Effort Apply - </strong>
                <div
                  className="advancedPricingText"
                  onClick={() =>
                    setAdvancedPricingItem({
                      id: item?.id,
                      type: "subservice",
                    })
                  }
                >
                  View / Edit
                </div>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const onUpdateServices = (subservices) => {
    // setFocusedId(null);
    setService({ ...service, subservices });
  };

  useEffect(() => {
    if (advancedPricingItem?.id) {
      setShowAdvancedPricingSlideout(true);
    }
  }, [advancedPricingItem]);

  return (
    <div className="generalTab">
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <div className="formSection">
        <Row className="firstRow">
          <Col sm={4} className="nameField">
            <TextField
              label="*Service Name"
              id="name"
              placeholder="Enter service name"
              value={service.name}
              onChange={(e) => setService({ ...service, name: e.target.value })}
              required
              readOnly={editing && permission !== "manage"}
            />
          </Col>
          <Col sm={4}>
            <RequiredReactSelect
              label="*Service Category"
              options={categoryOptions}
              placeholder="Select service category"
              value={
                categories.find((c) => c.value === service.category) ||
                undefined
              }
              onChange={(e) =>
                //@ts-ignore
                setService({ ...service, category: e.value })
              }
              isDisabled={editing && permission !== "manage"}
            />
          </Col>
          <Col sm={4}>
            <TextField
              label="SKU"
              id="sku"
              placeholder="Enter SKU"
              value={service?.sku || ""}
              onChange={(e) => setService({ ...service, sku: e.target.value })}
              required={false}
              readOnly={editing && permission !== "manage"}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={4}>
            {isManagedService ? (
              <RequiredReactSelect
                label="*Billing Frequency"
                options={frequencyOptions}
                placeholder="Select billing frequency"
                value={
                  frequencyOptions.find((o) => o.value === service.frequency) ||
                  undefined
                }
                onChange={(e) =>
                  //@ts-ignore
                  setService({ ...service, frequency: e.value })
                }
                isDisabled={editing && permission !== "manage"}
              />
            ) : (
              <RequiredReactSelect
                label="*Phase"
                options={phaseOptions}
                placeholder="Select phase"
                value={
                  phases.find((p) => p.value === service.phase) || undefined
                }
                onChange={(e) =>
                  //@ts-ignore
                  setService({ ...service, phase: e.value })
                }
                isDisabled={editing && permission !== "manage"}
              />
            )}
          </Col>
          <Col sm={4}>
            <FormNumberFieldV2
              label="Hours"
              required={false}
              min={0}
              step={0.01}
              value={service?.hours || ""}
              onChange={(e) =>
                setService({ ...service, hours: e.target.value })
              }
              placeholder="Enter hours"
              readOnly={editing && permission !== "manage"}
            />
          </Col>
          <Col sm={4}>
            <Select2V2
              label={`${!isManagedService ? "*" : ""}Resource`}
              options={resourceOptions}
              isDisabled={editing && permission !== "manage"}
              placeholder="Select resource"
              value={
                resourceOptions?.find((r) => r.value === service.resource) || {
                  value: -1,
                  label: "Select a Resource",
                }
              }
              onChange={(e) => setService({ ...service, resource: e.value })}
            />
          </Col>
        </Row>
        <Row className="variables">
          <Col>
            <div>
              {/* @ts-ignore */}
              <ProjectVariablesV2
                pvArrPassed={service?.variables || allProjectVariables}
                context="service"
                setState={(arr) => {
                  setService({
                    ...service,
                    variables: service?.variables?.map((originalPv) => {
                      arr.forEach((variable) => {
                        if (originalPv.name === variable.name) {
                          originalPv = variable;
                        }
                      });
                      return originalPv;
                    }),
                  });
                }}
                requiredOnly={true}
                unrequiredOnly={false}
                accountSlug={accountSlug}
                hasSectionTitle={false}
                hasTopBorder={false}
                hasGreyBox={false}
                has3PerRow={true}
              />
            </div>
          </Col>
        </Row>
        {editing && (
          <Row className="advancedPricing">
            {service?.hasAdvancedPricing && (
              <strong>Advanced Cost & Effort Apply - </strong>
            )}
            <div
              className="advancedPricingText"
              onClick={() =>
                setAdvancedPricingItem({ id: service?.id, type: "service" })
              }
            >
              {service?.hasAdvancedPricing
                ? "View / Edit"
                : "Set Advanced Cost & Effort"}
            </div>
          </Row>
        )}
      </div>
      <div className="subservicesContainer">
        <div className="headerContainer subservices">
          <div className="subserviceHeader">Subservices</div>
          <div className="actionBtns">
            <Button
              className="button"
              onClick={() => addSubservice()}
              disabled={loading}
            >
              Add Subservice
            </Button>
          </div>
        </div>
        {loading ? (
          <SmallSpinner />
        ) : (
          <div className="subserviceTable">
            <ServicesTable
              servicesPassed={service.subservices}
              onUpdateServices={onUpdateServices}
              serviceColumns={subserviceColumns}
              subserviceColumns={[]}
              addSubservice={() => null}
              deleteService={deleteSubservice}
              setAdvancedPricingItem={setAdvancedPricingItem}
              hasSettings={true}
              hasDragAndDrop={true}
              isCollapsible={false}
              placeholderText={
                editing
                  ? "No subservices found"
                  : "Add subservices to get started"
              }
            />
          </div>
        )}
        <ConfirmationModal
          show={showConfirmationModal}
          title="Delete Subservice"
          message={`Are you sure you want to delete${
            " " + subserviceToDelete?.name
          }?`}
          onConfirm={() => {
            setLoading(true);
            confirmDelete();
          }}
          onCancel={() => setShowConfirmationModal(false)}
        />
        {advancedPricingItem?.id && (
          <AdvancedServicePricing
            service={advancedPricingItem}
            slideoutOpen={showAdvancedPricingSlideout}
            setSlideoutOpen={(open) => {
              setShowAdvancedPricingSlideout(open);
              if (open) setAdvancedPricingItem(null);
              if (setResetData) setResetData(true);
              if (!changes) {
                refetch();
              } else {
                setService({
                  ...service,
                  subservices: service.subservices.map((s) => {
                    return { ...s, settingsOpen: false };
                  }),
                });
              }
            }}
          />
        )}
      </div>
      <div>
        {/* @ts-ignore */}
        <ProjectVariablesV2
          pvArrPassed={service?.variables || allProjectVariables}
          context="service"
          setState={(arr) => {
            setService({
              ...service,
              variables: service?.variables?.map((originalPv) => {
                arr.forEach((variable) => {
                  if (originalPv.name === variable.name) {
                    originalPv = variable;
                  }
                });
                return originalPv;
              }),
            });
          }}
          requiredOnly={false}
          unrequiredOnly={true}
          accountSlug={accountSlug}
          hasSectionTitle={true}
          hasTopBorder={true}
          hasGreyBox={true}
          has3PerRow={true}
        />
      </div>
    </div>
  );
};

export default General;
