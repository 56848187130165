import { useEffect, useState } from "react";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { getSurveyTags } from "../SurveyHelpers/surveyHelpers";
import { useNavigate } from "react-router";
import useListCompletedSurveys from "./api/useListCompletedSurveys";
import DataTable from "@components/DataTable";
import type { ColumnDef } from "@tanstack/react-table";
import { CompletedSurvey } from "./types";
import { faSortDown, faSortUp, faSort } from "@fortawesome/pro-solid-svg-icons";
import { capitalizeFirstLetter } from "@utils/helperFunctions";

interface CompletedSurveyProps {
  filterParams?: { name?: string };
  surveysCount: {
    completedSurveys?: number | null;
    takeSurveys?: number | null;
    manageSurveys?: number | null;
    archivedSurveys?: number | null;
  };
  setSurveysCount: (num) => void;
  projectId?: number;
  currentPage?: number;
  setCurrentPage?: (num) => void;
  refetchSurveys: boolean;
  setRefetchSurveys: (bool) => void;
}

const CompletedSurveys = ({
  filterParams,
  surveysCount,
  setSurveysCount,
  projectId,
  currentPage,
  setCurrentPage,
  refetchSurveys,
  setRefetchSurveys,
}: CompletedSurveyProps): JSX.Element => {
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(""); // State to track the sorting column
  const [sortOrder, setSortOrder] = useState("asc");

  const { completedSurveys, isLoading, pageMeta, isFetching, refetch } =
    useListCompletedSurveys({
      pageSize,
      pageNumber: currentPage || pageNumber,
      filterParams,
      sortBy,
      sortOrder,
      projectId,
    });

  useEffect(() => {
    if (
      !isLoading &&
      !isFetching &&
      pageMeta.recordCount !== surveysCount.completedSurveys
    )
      setSurveysCount({
        ...surveysCount,
        completedSurveys: pageMeta.recordCount,
      });
  }, [pageMeta]);

  useEffect(() => {
    if (refetchSurveys) {
      setRefetchSurveys(false);
      refetch();
    }
  }, [refetchSurveys]);

  const handleSort = (columnId: string) => {
    if (sortBy === columnId) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnId);
      setSortOrder("asc");
    }
  };

  const renderSortIcon = (columnId: string) => {
    if (sortBy === columnId) {
      return sortOrder === "asc" ? (
        <FontAwesomeIcon icon={faSortUp} />
      ) : (
        <FontAwesomeIcon icon={faSortDown} />
      );
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const columns: ColumnDef<CompletedSurvey>[] = [
    {
      id: "name",
      header: () => (
        <div className="surveysColumnHeader">
          Survey Name
          <div className="sortIcon" onClick={() => handleSort("name")}>
            {renderSortIcon("name")}
          </div>
        </div>
      ),
      cell(props) {
        const survey = props.row.original;
        const name = survey.attributes.name;
        return name === "No surveys found" ? (
          <>{name}</>
        ) : (
          <span
            className="surveyName"
            onClick={() => {
              return projectId
                ? navigate(`${survey?.attributes?.projectSurveyId || ""}`)
                : survey.attributes.questionnaireId
                ? navigate(
                    `/surveys/${survey.attributes.questionnaireId}/take`,
                    {
                      state: { surveyId: survey.id },
                    }
                  )
                : alert(
                    "This survey does not have any questions attached to it. Please go to settings to configure this survey's questions."
                  );
            }}
          >
            {name}
          </span>
        );
      },
    },
    {
      id: "status",
      header: () => (
        <div className="surveysColumnHeader">
          Status{" "}
          <div className="sortIcon" onClick={() => handleSort("status")}>
            {renderSortIcon("status")}
          </div>
        </div>
      ),
      cell(props) {
        const survey = props.row.original;
        const status = survey.attributes?.status;
        return status ? (
          <span className={`surveyStatus ${status}`}>
            {capitalizeFirstLetter(status)}
          </span>
        ) : (
          <></>
        );
      },
    },
    {
      id: "project.project_name",
      header: () => (
        <div className="surveysColumnHeader">
          Related Project{" "}
          <div
            className="sortIcon"
            onClick={() => handleSort("project.project_name")}
          >
            {renderSortIcon("project.project_name")}
          </div>
        </div>
      ),
      cell(props) {
        const survey = props.row.original;
        const projectId = survey?.attributes?.project?.id;
        return projectId ? (
          <div className="relatedProject">
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              onClick={() =>
                window.open(`/projects/${projectId}/edit`, "_blank")
              }
              className="previewProject"
            />
            <span
              onClick={() =>
                window.open(`/projects/${projectId}/edit`, "_blank")
              }
            >
              {survey?.attributes?.project?.name || ""}
            </span>
          </div>
        ) : (
          <></>
        );
      },
    },
    {
      id: "contact_name",
      header: () => (
        <div className="surveysColumnHeader">
          Completed By{" "}
          <div className="sortIcon" onClick={() => handleSort("contact_name")}>
            {renderSortIcon("contact_name")}
          </div>
        </div>
      ),
      cell(props) {
        return <>{props.row.original.attributes?.completedBy || ""}</>;
      },
    },
    {
      id: "updated_at",
      header: () => (
        <div className="surveysColumnHeader">
          Completed On{" "}
          <div className="sortIcon" onClick={() => handleSort("updated_at")}>
            {renderSortIcon("updated_at")}
          </div>
        </div>
      ),
      cell(props) {
        return <>{props.row.original.attributes?.completedOn || ""}</>;
      },
    },
    {
      id: "tag_list",
      header: "Tags",
      cell(props) {
        return getSurveyTags(props.row.original.attributes?.tags || []);
      },
    },
  ];

  return isLoading || isFetching ? (
    <SmallSpinner />
  ) : (
    <DataTable
      data={
        completedSurveys.length
          ? completedSurveys
          : [
              {
                id: "0",
                attributes: { name: "No surveys found" },
              },
            ]
      }
      columns={
        projectId
          ? columns.filter((c) => c.id !== "project.project_name")
          : columns.filter((c) => c.id !== "status")
      }
      bordered
      striped
      hover
      totalPages={pageMeta.pageCount}
      totalRows={pageMeta.recordCount}
      currentPage={currentPage || pageNumber}
      setCurrentPage={setCurrentPage || setPageNumber}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      paginationEnabled
      pageSizeEnabled
    />
  );
};

export default CompletedSurveys;
