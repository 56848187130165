import { Button } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import {
  buildConnectedAppAuthForm,
  buildConnectedAppFeatures,
  normalizeCasing,
  normalizeAppName,
} from "../common";
import { AuthField, ConnectionCategory } from "../types";
import {
  useCreateAccountConnectionMutation,
  useGetAccountQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleXmark,
} from "@fortawesome/pro-regular-svg-icons";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import useOauth from "@utils/customHooks/useOauth";
import API from "../../../../utils/API/API";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { useNavigate } from "react-router";
import useWhoAmI from "../../../Account/EditAccount/api/useWhoAmI";

const About = ({ app, setSelectedApp }) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [authorizationCode] = useOauth();
  const navigate = useNavigate();
  const appName = normalizeAppName(app.service);
  const isFileStorage = app.service === "merge_dev";
  const isOAuth = app?.authorization?.type === "oauth";
  const [createAccountConnection] = useCreateAccountConnectionMutation();

  const [errorMessage, setErrorMessage] = useState<string | string[]>(
    "Uh oh, something went wrong! Check your credentials and try again."
  );
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showFeaturesModal, setShowFeaturesModal] = useState(false);
  const [activating, setActivating] = useState(false);
  const checkConnectionStatusRef = useRef(true);
  const [newConnectionId, setNewConnectionId] = useState<number | null>(null);
  const [authFields, setAuthFields] = useState<AuthField[]>(
    app.authorization.fields.map((field) => {
      return { ...field, value: "" };
    })
  );
  const [categories, setCategories] = useState<ConnectionCategory[]>(
    app.categories.map((c) => {
      return { ...c, checked: app.categories.length === 1 ? true : false };
    })
  );
  const [mergeRegistrationEmail, setMergeRegistrationEmail] =
    useState("no-reply@test.com");

  const { data: account } = useGetAccountQuery({ slug: accountSlug });

  const { data: user, isLoading: userLoading } = useWhoAmI();

  useEffect(() => {
    const email = user?.attributes?.email;
    if (email && email !== mergeRegistrationEmail) {
      setMergeRegistrationEmail(email);
    }
  }, [userLoading]);

  const onClickBack = () => {
    setSelectedApp(null);
  };

  const getActivateModalBody = () => (
    <div className="activateModal">
      <div className="helpLink">
        <a href={app.helpUrl} target="_blank" className="setupLink">
          How do I set up {appName}?
        </a>
      </div>
      {buildConnectedAppAuthForm(authFields, setAuthFields)}
    </div>
  );

  const getFeaturesModalBody = () => {
    return activating ? (
      <SmallSpinner />
    ) : (
      <div className="featuresModal">
        <strong>Select features with which to integrate.</strong>
        {buildConnectedAppFeatures(categories, setCategories)}
      </div>
    );
  };

  const handleFailResponse = (msg) => {
    setActivating(false);
    setErrorMessage(
      `Uh oh, something went wrong! ${
        msg || "Check your credentials and try again."
      }`
    );
    setShowActivateModal(false);
    setShowFeaturesModal(false);
    setShowErrorModal(true);
  };

  const checkConnectionStatus = (connectionId, checkOnce) => {
    API.Get(
      `${apiHost}/${accountSlug}/v1/account-connections/${connectionId}`,
      authorizationCode
    )
      .then((response) => {
        const status = response?.data?.data?.attributes?.status;
        if (status === "Activating") {
          if (checkOnce) {
            handleFailResponse("An error occured activating this integration.");
            return;
          }

          // If status hasn't changed, wait 2 seconds and check again
          if (checkConnectionStatusRef.current) {
            setTimeout(() => {
              checkConnectionStatus(connectionId, false);
            }, 2000);
          } else {
            setActivating(false);
          }
        } else if (status === "Connected") {
          setActivating(false);
          setShowFeaturesModal(false);
          setShowSuccessModal(true);
        } else if (status === "Connection Error") {
          setActivating(false);
          handleFailResponse(`A ${appName} connection error has occurred.`);
        } else {
          setActivating(false);
          handleFailResponse("");
        }
      })
      .catch((err) => {
        if (isFileStorage) {
          setTimeout(() => {
            checkConnectionStatus(connectionId, false);
          }, 2000);
        }
        handleFailResponse(err?.data?.errors?.[0]?.detail);
      });
  };

  const handleActivate = () => {
    if (isFileStorage) {
      setShowFeaturesModal(true);
      setActivating(true);
    }
    let data = {
      type: "account-connections",
      attributes: {
        service: app.service,
      },
      relationships: {
        account: {
          data: {
            id: account?.data?.attributes?.["account-id"],
            type: "accounts",
          },
        },
      },
    };

    authFields.forEach((field, i) => {
      const source = field.path.split(".").slice(1, 2)[0]; // "credentials" | "settings" | "mappings"
      const attribute = field.path.split(".").pop() as string;

      if (!data.attributes[source]) {
        data.attributes[source] = { [attribute]: field.value };
      } else {
        data.attributes[source] = {
          ...data.attributes[source],
          [attribute]: field.value,
        };
      }
    });

    categories.forEach(
      (category) => (data.attributes[category.category] = category.checked)
    );

    if (isFileStorage) {
      //@ts-ignore
      data.attributes.credentials = {
        registration_email: mergeRegistrationEmail,
      };
      //@ts-ignore
      data.attributes.settings = { service_type: "filestorage" };
    }

    createAccountConnection({ slug: accountSlug, body: { data } })
      .unwrap()
      .then((response) => {
        const connection = response?.data;
        const oAuthURL = response?.data?.attributes?.["oauth-url"];
        const mergeURL = response?.data?.attributes?.["merge-auth-url"];
        if (connection?.id) {
          setNewConnectionId(connection.id);
          if (oAuthURL || mergeURL) {
            const windowFeatures = `width=600,height=600,left=${
              (window.screen.width - 600) / 2
            },top=${(window.screen.height - 600) / 2},resizable=yes`;

            const authWindow = window.open(
              oAuthURL || mergeURL,
              "_blank",
              windowFeatures
            );

            if (!authWindow) {
              handleFailResponse("Please enter a valid host.");
              return;
            }

            const checkOAuthCallback = setInterval(() => {
              if (authWindow?.closed) {
                clearInterval(checkOAuthCallback);
                checkConnectionStatus(connection.id, true);
              }
            }, 1000);
          } else {
            checkConnectionStatus(connection.id, false);
          }
        }
      })
      .catch((error) => handleFailResponse(""));
  };

  const validateCredentials = (authFields) => {
    const invalid: AuthField[] = [];
    authFields.forEach((field) => {
      if (field.input !== "checkbox" && !field.value) {
        invalid.push(field);
      }
    });

    if (invalid.length) {
      setErrorMessage(
        invalid.length === 1
          ? `${invalid[0].label} cannot be blank.`
          : [
              "The following fields cannot be blank:",
              ...invalid.map((field) => `- ${field.label}`),
            ]
      );
      setShowFailAlert(true);
    } else {
      setShowActivateModal(false);
      setShowFeaturesModal(true);
    }
  };

  return (
    <div data-testid="aboutConnectionPage" className="aboutConnectionPage">
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <div className="connectionsHeader">
        <div className="info">
          <div className="logoBox spaceRight">
            <img className="smallLogoImg" src={app.logo} />
          </div>
          <div>
            <h3 className="text24 appName">{appName}</h3>
            <div className="summary headerSummary">{app.summary}</div>
            {app.helpUrl && (
              <a href={app.helpUrl} target="_blank" className="setupLink">
                How do I set up {appName}?
              </a>
            )}
          </div>
        </div>
        <div className="flexCenter">
          <Button onClick={onClickBack} className="ssButtonBgWhite spaceRight">
            Back
          </Button>
          <Button
            onClick={() =>
              isFileStorage ? handleActivate() : setShowActivateModal(true)
            }
            className="squareGreenButton"
          >
            Add Integration
          </Button>
        </div>
      </div>
      <hr></hr>
      {app?.providers && (
        <>
          <h3 className="text24">Supported Providers</h3>
          <div className="providersContainer">
            {app.providers.map((provider, i) => {
              return <img key={i} className="provider" src={provider.logo} />;
            })}
          </div>
        </>
      )}
      <h3 className="text24">About {appName}</h3>
      <p className="instructionalText">{app.description}</p>
      <h3 className="text24">Features</h3>
      <ul className="featureList">
        {app.features.map((feature) => (
          <li className="instructionalText" key={feature}>
            {feature}
          </li>
        ))}
      </ul>
      {app?.service !== "merge_dev" && (
        <>
          <h3 className="text24">Integration Supports</h3>
          <p className="instructionalText">
            {app.categories.map((cat, index) => {
              if (index === app.categories.length - 1) {
                return <span key={index}>{normalizeCasing(cat.category)}</span>;
              } else if (index === app.categories.length - 2) {
                return (
                  <span key={index}>
                    {normalizeCasing(cat.category)}
                    {app.categories.length > 2 ? "," : null}&nbsp;and&nbsp;
                  </span>
                );
              } else {
                return (
                  <span key={index}>
                    {normalizeCasing(cat.category)},&nbsp;
                  </span>
                );
              }
            })}
          </p>
        </>
      )}
      {/* Auth credentials modal */}
      <ScopeStackModal
        modalTitle="Configure Integration"
        modalBody={getActivateModalBody()}
        button1Text="Cancel"
        handleButton1Click={() => setShowActivateModal(false)}
        button2Text={"Continue"}
        handleButton2Click={() => {
          validateCredentials(authFields);
        }}
        button2Disabled={
          authFields.filter((f) => f.input !== "checkbox" && !f.value).length
            ? true
            : false
        }
        show={showActivateModal}
        handleClose={() => setShowActivateModal(false)}
      />
      {/* CRM, PSA, Product modal */}
      <ScopeStackModal
        modalTitle="Configure Integration"
        modalBody={getFeaturesModalBody()}
        button1Text={activating ? undefined : "Cancel"}
        handleButton1Click={() => setShowFeaturesModal(false)}
        button2Text={
          activating
            ? undefined
            : isOAuth || isFileStorage
            ? "Continue"
            : "Complete"
        }
        handleButton2Click={() => {
          setActivating(true);
          handleActivate();
        }}
        button2Disabled={
          categories.filter((c) => c.checked).length ? false : true
        }
        show={showFeaturesModal}
        handleClose={() => {
          checkConnectionStatusRef.current = false;
          setShowFeaturesModal(false);
        }}
      />
      {/* Success Alert modal */}
      <ScopeStackModal
        modalTitle="Configure Integration"
        modalBody={
          <div className="activateAlertModal">
            <FontAwesomeIcon icon={faCheckCircle} className="activateSuccess" />{" "}
            This integration has connected successfully!
          </div>
        }
        button1Text={undefined}
        handleButton1Click={() => null}
        button2Text={undefined}
        handleButton2Click={() => null}
        show={showSuccessModal}
        handleClose={() => {
          setShowSuccessModal(false);
          if (newConnectionId) {
            navigate(`manage/${newConnectionId}`, { state: app });
          }
        }}
      />
      {/* Error Alert modal */}
      <ScopeStackModal
        modalTitle="Configure Integration"
        modalBody={
          <div className="activateAlertModal">
            <FontAwesomeIcon icon={faCircleXmark} className="activateError" />{" "}
            {errorMessage}
          </div>
        }
        button1Text={undefined}
        handleButton1Click={() => null}
        button2Text={undefined}
        handleButton2Click={() => null}
        show={showErrorModal}
        handleClose={() => {
          setShowErrorModal(false);
          if (newConnectionId) {
            navigate(`manage/${newConnectionId}`, { state: app });
          }
        }}
      />
    </div>
  );
};

export default About;
