import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import VendorInfo from "../Common";
import { Vendor } from "../Common/types";
import useGetVendor from "../Common/api/useGetVendor";

const Edit = () => {
  const { id } = useParams();
  const vendorId = Number(id) || 0;
  const { vendor: existingVendor, isLoading, error } = useGetVendor(vendorId);
  const [vendorAttributes, setVendorAttributes] = useState<Vendor>({
    id: 0,
    type: "vendors",
    attributes: {
      name: "",
      streetAddress: "",
      street2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    },
    relationships: {
      account: { data: { type: "accounts", id: 0 } },
    },
  });
  useEffect(() => {
    if (existingVendor?.attributes && vendorId !== 0) {
      setVendorAttributes((vendor) => {
        if (vendor.id !== 0) return vendor;

        return {
          id: existingVendor.id,
          type: "vendors",
          attributes: {
            name: existingVendor.attributes.name || "",
            streetAddress: existingVendor.attributes.streetAddress || "",
            street2: existingVendor.attributes.street2 || "",
            city: existingVendor.attributes.city || "",
            state: existingVendor.attributes.state || "",
            postalCode: existingVendor.attributes.postalCode || "",
            country: existingVendor.attributes.country || "",
          },
          relationships: existingVendor.relationships || {},
        };
      });
    }
  }, [existingVendor, vendorId]);

  function onSubmit() {}

  return (
    <VendorInfo
      mode="edit"
      vendor={vendorAttributes}
      setVendorAttributes={setVendorAttributes}
      onSubmit={onSubmit}
    />
  );
};

export default Edit;
