import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import "../style/style.css";
import {
  useUpdateSurveyMutation,
  V1SurveyResource,
  useCalculateSurveyRecommendationsMutation,
  UpdateSurveyApiResponse,
  CalculateSurveyRecommendationsApiResponse,
  useGetProjectByIdQuery,
} from "@generated";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { Survey, Questionnaire } from "../types";
import useOauth from "@utils/customHooks/useOauth";
import { Service } from "../../Projects/ServicesV2/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-solid-svg-icons";
import { renderResourceOptions } from "@utils/helperFunctions";
import {
  useListResourceRates,
  useListProjectResources,
  useListResources,
} from "../../Projects/ServicesV2/api";
import Select from "react-select";
import useGetAccount from "../../Account/EditAccount/api/useGetAccount";
import { Question } from "../types/Question";
import { ServicePreview } from "@components/PreviewSlideouts";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import QuestionnaireForm from "@components/TakeSurvey/QuestionnaireForm/QuestionnaireForm";
import SurveyRecommendations from "@components/TakeSurvey/SurveyRecommendations";
import { applySurvey as handleApply, formatRecommendations } from "../common";
import API from "../../../utils/API/API";

const Edit = ({ project_id }) => {
  const [authorizationCode] = useOauth();
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const location = useLocation();
  //boolean as to whether user came from project > surveys OR project > PS services
  const fromProjectServices = location?.state?.from?.includes("take");

  const projectSurveyId = window.location.href.split("/").slice(-1)[0];

  const [questions, setQuestions] = useState<Question[] | null>(null);
  const [questionnaire, setQuestionnaire] =
    useState<Questionnaire | null>(null);

  const [recommendations, setRecommendations] = useState<
    Survey["recommendations"]
  >([]);

  const [step, setStep] = useState<1 | 2>(2);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [serviceToPreview, setServiceToPreview] = useState<Service>({
    id: "0",
    name: "",
  });
  const [slideoutOpen, setSlideoutOpen] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);

  const [survey, setSurvey] = useState<Survey | null>(null);
  const [updateSurvey] = useUpdateSurveyMutation();
  const [calculate] = useCalculateSurveyRecommendationsMutation();

  const { data: project } = useGetProjectByIdQuery({
    slug: accountSlug,
    id: Number(project_id),
    include: ["rate-table"],
  });

  const { data: account, isLoading: accountLoading } =
    useGetAccount(accountSlug);

  const rateTableId = project?.data?.relationships?.["rate-table"]?.data?.id;
  const resourceRatesEnabled =
    account?.attributes?.settings?.project_resource_rates;
  const { resourceRates } = useListResourceRates(rateTableId);
  const { resources } = useListResources();
  const { projectResources } = useListProjectResources(project_id);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      if (authorizationCode) {
        try {
          const res = await API.Get(
            `${apiHost}/${accountSlug}/v1/project-surveys/${projectSurveyId}?include=survey,survey.questionnaire,survey.questionnaire.questions,survey.survey-recommendations`,
            authorizationCode
          );
          const included = res.data.included;
          console.log("project survey response", res);

          const survey = included.find((item) => item.type === "surveys");
          const questionnaire = included.find(
            (item) => item.type === "questionnaires"
          );

          setQuestionnaire({
            id: questionnaire.id,
            name: questionnaire.attributes.name,
            introduction: questionnaire.attributes.introduction,
            questions: questionnaire.attributes.introduction,
          });
          setQuestions(survey?.attributes?.responses || []);

          const recommendations = survey?.attributes?.recommendations?.map(
            (rec) => {
              const recommendation = included.find(
                (item) =>
                  item.type === "survey-recommendations" && item.id == rec.id
              );
              return {
                ...rec,
                status: recommendation?.attributes?.status,
              };
            }
          );

          const formattedRecommendations = await formatRecommendations(
            recommendations,
            authorizationCode,
            accountSlug,
            handleFailResponse
          );

          const { name, responses, calculations } = survey.attributes;

          setSurvey({
            id: survey.id,
            name,
            responses,
            calculations,
          });
          setRecommendations(formattedRecommendations);
          setLoading(false);
        } catch (error) {
          handleFailResponse("");
        }
      }
    };

    fetchData();
  }, [authorizationCode]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (unsavedChanges) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [unsavedChanges]);

  const handleFailResponse = (msg) => {
    setErrorMessage(msg || "Something went wrong");
    setShowFailAlert(true);
    setLoading(false);
  };

  const handleCalculate = async () => {
    if (!survey?.id || !questionnaire?.id) return;

    const missingRequiredQuestion = questions?.some(
      (q) =>
        q.required &&
        !q.answer &&
        Array.isArray(q?.["select-options"]) &&
        !q?.["select-options"]?.find((o) => o.default)
    );

    if (missingRequiredQuestion) {
      handleFailResponse("Please complete all required fields");
      return;
    }

    setLoading(true);

    // Patch survey.responses
    const responses = questions?.map((q) => {
      let defaultValue;
      if (Array.isArray(q?.["select-options"])) {
        defaultValue = q?.["select-options"]?.find((o) => o.default);
      }
      return {
        "question-id": q?.["question-id"],
        question: q?.question,
        //@ts-ignore
        answer: q?.answer || defaultValue?.value,
        "survey-response-id": q?.["survey-response-id"],
      };
    });

    try {
      const data: V1SurveyResource = {
        id: Number(survey.id),
        type: "surveys",
        attributes: {
          name: survey.name || "",
          responses,
        },
        relationships: {
          questionnaire: {
            data: { id: Number(questionnaire.id), type: "questionnaires" },
          },
        },
      };

      const updatedSurvey = (await updateSurvey({
        slug: accountSlug,
        id: Number(survey.id),
        body: { data },
      })) as UpdateSurveyApiResponse;

      // Calculate
      if (updatedSurvey?.data) {
        const updatedResponses =
          //@ts-ignore
          updatedSurvey?.data?.data?.attributes?.responses?.map((r) => {
            const question = questions?.find(
              (q) => String(q["question-id"]) == String(r["question-id"])
            );
            return {
              ...question,
              "survey-response-id": r["survey-response-id"],
            };
          });

        if (updatedResponses) setQuestions(updatedResponses);

        const calculated = (await calculate({
          slug: accountSlug,
          id: Number(survey.id),
        })) as CalculateSurveyRecommendationsApiResponse;

        if (calculated?.data) {
          const updated =
            //@ts-ignore
            calculated.data?.data?.attributes;

          if (updated?.recommendations?.length) {
            const formattedRecommendations: Survey["recommendations"] =
              await formatRecommendations(
                updated?.recommendations,
                authorizationCode,
                accountSlug,
                handleFailResponse
              );

            setRecommendations(formattedRecommendations);
            setSurvey({
              ...survey,
              responses: updated.responses,
              calculations: updated.calculations,
            });
            setQuestions(updated.responses);

            setStep(2);
            setLoading(false);
          } else {
            handleFailResponse("");
          }
        }

        //@ts-ignore
        const error = calculated?.error?.data?.errors?.[0]?.detail;
        if (error) handleFailResponse(error);
      }

      //@ts-ignore
      const error = updatedSurvey?.error?.data?.errors?.[0]?.detail;
      if (error) handleFailResponse(error);
    } catch (err) {
      handleFailResponse("");
    }
  };

  const reviewColumns = [
    {
      id: "service name",
      header: () => "Service Name",
      cell(item) {
        return item.id !== "noRow" ? (
          <div className="serviceName">
            <Form.Control type="text" value={item.name} disabled={true} />
            {item.type === "services" && (
              <div className="previewService review">
                <FontAwesomeIcon
                  icon={faEye}
                  onClick={() => {
                    setServiceToPreview(item);
                    setSlideoutOpen(true);
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          item.name
        );
      },
    },
    {
      id: "total hours",
      header: () => "Total Hours",
      cell(item) {
        return (
          item.id !== "noRow" && (
            <Form.Control
              type="text"
              value={item.hours || ""}
              disabled={true}
            />
          )
        );
      },
    },
    {
      id: "qty",
      header: () => "QTY",
      cell(item) {
        return (
          item.id !== "noRow" && (
            <Form.Control
              type="number"
              min="0"
              step="1"
              defaultValue={item.quantity || 1}
              onChange={() => setUnsavedChanges(true)}
              onBlur={(e) => {
                item = {
                  ...item,
                  quantity: e.target.value,
                };
                const updated = (recommendations || []).map((s) => {
                  if (item.type === "subservices") {
                    return {
                      ...s,
                      subservices:
                        s.subservices?.map((sub) =>
                          sub.id === item.id
                            ? { ...item, isChanged: true }
                            : sub
                        ) || [],
                    };
                  }

                  return s.id === item.id ? item : s;
                });
                setRecommendations(updated);
              }}
            />
          )
        );
      },
    },
    {
      id: "resource",
      header: () => "Resource",
      cell(item) {
        const service =
          item?.type === "subservices"
            ? recommendations?.find((s) => s.id === item?.serviceId)
            : undefined;

        //Use the defined resource if it exists, otherwise, if it is a subservice, default to service resource
        const resource =
          item?.resource || service?.resource
            ? {
                label: item?.resource?.name || service?.resource?.name,
                value: item?.resource?.id || service?.resource?.id,
              }
            : undefined;

        return (
          item?.id !== "noRow" &&
          item?.serviceType == "professional_services" && (
            <Select
              className="resourceDropdown"
              value={resource}
              options={renderResourceOptions(
                projectResources,
                resourceRatesEnabled ? resourceRates : resources,
                []
              )}
              onChange={(e) => {
                if (e) {
                  item = {
                    ...item,
                    resource: { id: e.value, name: e.label },
                  };
                  const updated = (recommendations || []).map((s) => {
                    if (item.type === "subservices") {
                      return {
                        ...s,
                        subservices:
                          s.subservices?.map((sub) =>
                            sub.id === item.id
                              ? { ...item, isChanged: true }
                              : sub
                          ) || [],
                      };
                    }

                    return s.id === item.id ? item : s;
                  });
                  setRecommendations(updated);
                  setUnsavedChanges(true);
                }
              }}
            />
          )
        );
      },
    },
  ];

  const showSpinner =
    loading ||
    accountLoading ||
    !questions ||
    !survey ||
    !questionnaire ||
    !recommendations;

  if (showSpinner) return <ScopeStackSpinner />;

  const itemsPending =
    recommendations?.filter((item) => item?.status === "pending")?.length > 0;

  return (
    <div className="servicesV2 applySurvey">
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <div className="actionBtns">
        <div className="leftHeader">
          {" "}
          <Button
            className="button cancelButton"
            onClick={(e) => {
              if (step === 1) {
                setQuestions(survey.responses || []);
                setStep(2);
              } else {
                window.location.replace(
                  `${appHost}/projects/${project_id}/project_surveys`
                );
              }
            }}
          >
            Cancel
          </Button>
          <div className="servicesH1">
            Edit {questionnaire.name || "Survey"}
          </div>
        </div>

        {step === 1 ? (
          <Button
            className={`button`}
            onClick={() => handleCalculate()}
            disabled={!questions?.length || showSpinner}
          >
            Calculate
          </Button>
        ) : (
          <div className="rightHandButtons">
            <Button className="button cancelButton" onClick={(e) => setStep(1)}>
              Edit Responses
            </Button>
            {(unsavedChanges || itemsPending) && (
              <Button
                className="button"
                onClick={(e) => {
                  setLoading(true);
                  setUnsavedChanges(false);
                  handleApply(
                    recommendations!,
                    Number(survey.id),
                    project_id,
                    { slug: accountSlug, code: authorizationCode },
                    handleFailResponse,
                    fromProjectServices
                  );
                }}
              >
                Apply to Project
              </Button>
            )}
          </div>
        )}
      </div>
      {step === 1 ? (
        <QuestionnaireForm
          questionnaire={questionnaire}
          questions={questions}
          setQuestions={setQuestions}
        />
      ) : (
        <SurveyRecommendations
          columns={reviewColumns}
          recommendations={recommendations || []}
          calculations={survey?.calculations || []}
          responses={survey?.responses || []}
        />
      )}
      <ServicePreview
        service={serviceToPreview}
        slideoutOpen={slideoutOpen}
        setSlideoutOpen={setSlideoutOpen}
        isManagedService={serviceToPreview?.serviceType === "managed_services"}
      />
    </div>
  );
};

export default Edit;
