import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { Route, Routes } from "react-router-dom";
import { useAuthorization } from "../../../common/providers/AuthorizationProvider";
import "./style.css";
import Show from "./Show/Show";
import Create from "./Create";
import Edit from "./Edit";

const VendorsV2Mspa = (): JSX.Element => {
  const authorized = useAuthorization();
  if (!authorized) {
    return <ScopeStackSpinner />;
  }

  return (
    <div className="vendorsV2">
      <Routes>
        <Route path="/" element={<Show />} />
        <Route path="create" element={<Create />} />
        <Route path="/edit/:id" element={<Edit />} />
      </Routes>
    </div>
  );
};

export default VendorsV2Mspa;
