import React, { useEffect, useState, createRef, useRef } from "react";
import { Card, Form, FormGroup, Row, Col, Button } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";
import MultipleLineField from "@components/Forms/MulitpleLineField";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import {
  removeDuplicates,
  formatUnprocessibleResponse,
  handleAlert,
} from "@utils/helperFunctions";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import useUnloadWarning from "@utils/customHooks/useUnloadWarning";
import { GenericToggle } from "@components/FormsV2/GenericToggle";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import FontAwesomeIconTooltipped from "@components/Buttons/FontAwesomeIconTooltipped/FontAwesomeIconTooltipped";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";

function ProjectConditions({ account_slug, project_id, account_id }) {
  const [authorizationCode] = useOauth();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [projectConditions, setProjectConditions] = useState([]);
  const [conditionsChanged, setConditionsChanged] = useState([]);
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [projectStatus, setProjectStatus] = useState("");

  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const successMessage = "Your changes were saved!";
  const [isFormDirty, setFormDirty] = useState(false);

  useUnloadWarning(isFormDirty);

  const [isLoading, setIsLoading] = useState(true);
  const [editorRefs, setEditorRefs] = useState([]);
  const toolbarItems = [
    ['heading', 'bold', 'italic'],
    ['ul', 'ol', 'indent', 'outdent'],
    ['table', 'link'],
  ];

  useEffect(() => {
    if (authorizationCode !== "") {
      // Get project conditions
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=project-conditions`,
        authorizationCode
      ).then((res) => {
        const data = res.data.data;
        setProjectStatus(data.attributes.status);

        if (res.data.included) {
          let conditions = res.data.included.filter(
            (item) => item.type === "project-conditions"
          );
          setProjectConditions(
            conditions.map((c) => {
              c.attributes.skip = (c.attributes.status != "active");
              return c;
            })
          );
        }
        setIsLoading(false);
      });
    }
  }, [authorizationCode]);

  useEffect(() => {
  // add or remove refs
    setEditorRefs((editorRefs) =>
      Array(projectConditions.length)
        .fill()
        .map((_, i) => editorRefs[i] || createRef()),
    );    
  }, [projectConditions]);

  const handleToggleCondition = (condition) => {
    let baseUrl = `${apiHost}/${account_slug}/v1/project-conditions/${condition.id}/`;
    if (condition.attributes.skip === true) {
      baseUrl += "skip";
    } else {
      baseUrl += "restore";
    }
    API.Put(baseUrl, {}, authorizationCode)
      .then((res) => {
        if (res.status == 200) {
          setShowSuccessAlert(true);
        }
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "condition"));
        setShowFailAlert(true);
      });
  };

  const getLabel = (condition) => {
    return (
      <div style={{ display : "flex", justifyContent : "space-between" }}>
        <label htmlFor={'editor-' + condition.id}
               className={'form-label'}
               style={{ paddingTop : "0.6em"}}
               id={'editor-label-' + condition.id}
               key={'editor-label-' + condition.id}
        >
          {condition.attributes.name}
        </label>
        <div style={{ width : '10em', display : 'flex' }}>
          <GenericToggle
            hasText={true}
            onText="Included"
            offText="Excluded"
            initialValueOfIsOn={!condition.attributes.skip}
            onToggle={() => {
              const newProjectConditions = projectConditions.map((c) => {
                if (c.id == condition.id) {
                  c.attributes.skip = !c.attributes.skip;
                }
                return c;
              });
              handleToggleCondition(condition);
              setProjectConditions(newProjectConditions);
            }}
          />
          <FontAwesomeIconTooltipped
            icon={faCircleInfo}
            style={{ color: "#418172", fontSize: "16px", marginTop: "0.6em", marginLeft : "0.6em" }}
            className={'pull-right'}
            toolTipText={
              condition.attributes.skip
                ? "You can toggle this on to include this condition in the generated document. It is currently excluded."
                : "You can toggle this off to exclude this condition from the generated document."
            }
          />
        </div>
      </div>
    );
  };

  const renderProjectConditions = () => {
    return projectConditions.map((condition, idx) => {
      return (
        <Row>
          <Col style={{ paddingLeft : '2em', paddingRight : '2em', }}>
            <FormGroup>
              {getLabel(condition)}
              <Editor
                id={'editor-' + condition.id}
                key={'editor-' + condition.id}
                initialValue={condition.attributes.content}
                initialEditType='wysiwyg'
                useCommandShortcut={true}
                hideModeSwitch={true}
                height="200px"
                style={{ backgroundColor : condition.attributes.skip ? 'grey' : 'white' }}
                ref={editorRefs[idx]}
                toolbarItems={toolbarItems}
                onBlur={(e) => {
                  if(editorRefs[idx]) {
                    setConditionsChanged([
                      ...conditionsChanged,
                      { id: condition.id, content: editorRefs[idx].current.editorInst.getMarkdown() },
                    ]);
                  }
                }}
                disabled={condition.attributes.skip}
              />
            </FormGroup>
          </Col>
        </Row>
      )
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormDirty(false);
    let projectPromises = [];
    let conditions = removeDuplicates(conditionsChanged, (item) => item.id);
    if (conditions.length !== 0) {
      conditions.forEach((condition) => {
        const conditionData = {
          data: {
            type: "project-conditions",
            id: condition.id,
            attributes: {
              content: condition.content,
            },
            relationships: {
              project: { data: { type: "projects", id: project_id } },
            },
          },
        };

        projectPromises.push(
          API.Patch(
            `${apiHost}/${account_slug}/v1/project-conditions/${condition.id}`,
            conditionData,
            authorizationCode
          )
        );
      });

      Promise.all(projectPromises)
        .then((res) => {
          setConditionsChanged([]);
          setUpdatedAlertIsOpen(true);
          setTimeout(() => {
            setUpdatedAlertIsOpen(false);
          }, 3000);
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "condition"));
          setShowFailAlert(true);
        });
    }
  };

  const handleFormChange = (event) => {
    setFormDirty(true);
  };

  const conditionsUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="conditionsAlert"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text="Terms and Conditions updated"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {conditionsUpdatedAlert()}
      {handleFailAlert()}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <Card className="whiteBg details">
        <Card.Body>
          <Card.Header className="cardHeaderOverride">
            <div className="headerContainer">
              <div className="actionBtns">
                <h2>
                  <span className="text24">Terms and Conditions</span>
                </h2>
              </div>
              { projectStatus === "building" && (
                <div className="actionBtns">
                  <Button
                    className="btnSeafoam"
                    type="button"
                    onClick={(e) => {
                      handleSubmit(e)
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </Card.Header>
          {isLoading ? (
            <ScopeStackSpinner />
          ) : (
            <Form onChange={handleFormChange}>
              {renderProjectConditions()}
            </Form>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProjectConditions;
