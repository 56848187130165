import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import SearchField from "@components/Forms/SearchField/SearchField";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import TakeSurvey from "../../Surveys/TakeSurvey/TakeSurvey";
import CompletedSurveys from "../../Surveys/CompletedSurveys/CompletedSurveys";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import SlideOutMenuSectioned from "@components/SlideOutMenu/SlideOutMenuSectioned";
import { Tab, Tabs } from "react-bootstrap";

interface Props {
  project_id: number;
  hideCompletedSurveys?: boolean;
}

const Show = ({ project_id, hideCompletedSurveys }: Props) => {
  const accessToken = useSelector((state: RootState) => state.auth.token);

  const [searchInput, setSearchInput] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentTab, setCurrentTab] = useState<string | null>("1");

  const [filterParams, setFilterParams] = useState<{
    name?: string;
  }>({ name: undefined });

  const [showSlideOutMenu, setShowSlideOutMenu] = useState(false);
  const [slideOutContent, setSlideOutContent] = useState<any>({
    title: "Filter By",
    content: [],
  });
  const [surveysCount, setSurveysCount] = useState({
    takeSurveys: null,
    completedSurveys: null,
  });

  const [refetchSurveys, setRefetchSurveys] = useState<boolean>(false);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchName();
  };

  const searchName = () => {
    setFilterParams({ name: searchInput || undefined });
  };

  return (
    <div className="servicesV2 applySurvey">
      {" "}
      <div className="actionBtns">
        <div className="servicesH1">Surveys</div>
      </div>
      <div className={`searchField surveys`}>
        <FormFieldLabel label="Search surveys" className={undefined} />

        <Form
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setCurrentPage(1);
              handleSearch(e);
            }
          }}
          onSubmit={(e) => {
            setCurrentPage(1);
            handleSearch(e);
          }}
        >
          <SearchField
            placeholder="Search by survey name"
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            value={searchInput}
            onClick={(e) => {
              setSearchInput("");
              setFilterParams({ name: undefined });
            }}
            fullWidth
          />
        </Form>
      </div>
      {!hideCompletedSurveys ? (
        <Tabs
          defaultActiveKey={"1"}
          id="uncontrolled-tab"
          className="mb-3"
          onSelect={(e) => {
            if (currentTab === "2") {
              setRefetchSurveys(true);
            }
            setCurrentTab(e);
          }}
        >
          <Tab
            eventKey={"1"}
            title={`Associated Surveys ${
              currentTab === "1" && surveysCount.completedSurveys !== null
                ? `(${surveysCount.completedSurveys})`
                : ""
            }`}
            tabClassName="surveysTab"
            className="surveysTabContent completedSurveys"
          >
            {currentTab === "1" && (
              <CompletedSurveys
                surveysCount={surveysCount}
                setSurveysCount={setSurveysCount}
                filterParams={filterParams}
                projectId={project_id}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                refetchSurveys={refetchSurveys}
                setRefetchSurveys={setRefetchSurveys}
              />
            )}
          </Tab>
          <Tab
            eventKey={"2"}
            title={`Take a Survey ${
              currentTab === "2" && surveysCount.takeSurveys !== null
                ? `(${surveysCount.takeSurveys})`
                : ""
            }`}
            tabClassName="surveysTab"
            className="surveysTabContent takeSurveys"
          >
            <TakeSurvey
              filterParams={filterParams}
              surveysCount={surveysCount}
              setSurveysCount={setSurveysCount}
              setShowSlideOutMenu={setShowSlideOutMenu}
              setSlideOutContent={setSlideOutContent}
              accessToken={accessToken || ""}
              projectId={project_id}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              hideShare={false}
            />
          </Tab>
        </Tabs>
      ) : (
        <TakeSurvey
          filterParams={filterParams}
          surveysCount={surveysCount}
          setSurveysCount={setSurveysCount}
          setShowSlideOutMenu={setShowSlideOutMenu}
          setSlideOutContent={setSlideOutContent}
          accessToken={accessToken || ""}
          projectId={project_id}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          hideShare={true}
        />
      )}
      <SlideOutMenuSectioned
        show={showSlideOutMenu}
        setShow={setShowSlideOutMenu}
        menuHeader={slideOutContent.title}
        actionButtonText={null}
        onActionButtonClick={() => setShowSlideOutMenu(false)}
        clearFields={null}
        onCancel={() => setShowSlideOutMenu(false)}
        onClearFieldsClick={() => null}
        sectionsArray={slideOutContent.content}
      />
    </div>
  );
};

export default Show;
