import ProductInfo from "../common";
import { useState } from "react";
import { Product } from "../common/types";

const Create = () => {
  const [product, setProduct] = useState<Product>({
    id: 0,
    type: "products",
    attributes: {
      name: "",
      description: "",
      sku: "",
      intProdID: "",
      mfgPartNum: "",
      units: "",
      category: "",
      subcategory: "",
      unitPrice: 0,
      unitCost: 0,
      listPrice: 0,
    },
  });

  const onSubmit = () => {};

  return (
    <ProductInfo
      product={product}
      setProductAttributes={setProduct}
      mode="create"
      onSubmit={onSubmit}
    />
  );
};

export default Create;
