import {
  useGetProjectSurveyQuery,
  V1QuestionnaireResource,
  V1SurveyResource,
  V1QuestionResource,
} from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { ProjectSurvey } from "../types";

const useGetProjectSurvey = ({ id }: { id: number }) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, isLoading, isFetching, refetch } = useGetProjectSurveyQuery({
    slug: accountSlug,
    id,
    include: [
      "survey",
      //@ts-ignore
      "survey.questionnaire",
      //@ts-ignore
      "survey.questionnaire.questions",
    ],
  });
  let projectSurvey: ProjectSurvey | null = null;

  if (data?.data) {
    if (!data.included) {
      throw new Error("Missing data.included");
    }
    const survey = data.included?.find(
      (item) => item.type === "surveys"
    ) as V1SurveyResource;

    if (!survey) {
      throw new Error("Missing associated survey");
    }

    const questionnaire = data.included?.find(
      (item) => item.type === "questionnaires"
    ) as V1QuestionnaireResource;

    const questions =
      (data?.included?.filter(
        //@ts-ignore
        (item) => item.type === "questions" && item?.attributes?.active
      ) as V1QuestionResource[]) || [];

    if (!questionnaire || !questions) {
      throw new Error("Missing associated questionnaire");
    }

    const recommendations = survey?.attributes?.recommendations?.map(
      (record) => {
        const recommendation = data?.included?.find(
          (item) =>
            item.type === "survey-recomemndations" &&
            //@ts-ignore
            String(item.id) === String(record.id)
        );

        return {};
      }
    );

    projectSurvey = {
      id: String(id),
      type: "project-surveys",
      survey: {
        id: String(survey?.id),
        name: survey?.attributes.name || "",
        responses: survey?.attributes?.responses || [],
        calculations: survey?.attributes?.calculations || [],
      },
      questionnaire: {
        id: String(questionnaire.id),
        name: questionnaire?.attributes?.name || "",
        introduction: questionnaire?.attributes?.introduction || "",
        questions: questions
          .sort((a, b) => a?.attributes?.postion! - b?.attributes?.postion!)
          .map((q) => {
            return {
              ...q.attributes,
              "question-id": q?.id,
            };
          }),
      },
    };
  }
  return {
    projectSurvey,
    surveyLoading: isLoading,
    surveyFetching: isFetching,
    surveyRefetch: refetch,
  };
};

export default useGetProjectSurvey;
