import DataTable from "@components/DataTable";
import useListVendorsForAccount from "./api";
import { Vendor } from "./types";
import { Button, Card, Form } from "react-bootstrap";
import { SetStateAction, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useDeleteVendorMutation } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import SearchField from "@components/Forms/SearchField/SearchField";
import { useNavigate } from "react-router-dom";
import SlideOutMenuSectioned from "@components/SlideOutMenu/SlideOutMenuSectioned";

const Show = (): JSX.Element => {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [vendorToSearch, setVendorToSearch] = useState("");
  const [filter, setFilter] = useState<{ name?: string }>({});
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [deleteVendor, {}] = useDeleteVendorMutation();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteQueue, setDeleteQueue] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [slideOutContent, setSlideOutContent] = useState<{
    title: string;
    content: any[];
  }>({ title: "", content: [] });

  const { vendors, pageMeta, isLoading, refetch } = useListVendorsForAccount({
    page: {
      number: pageNumber,
      size: pageSize,
    },
    filter,
  });

  const handleDelete = () => {
    setDeleteQueue([...selectedIds]);
    setShowConfirmationModal(true);
  };

  const confirmDelete = () => {
    if (deleteQueue.length === 0) return;

    const id = deleteQueue[0];
    deleteVendor({ slug: accountSlug, id: parseInt(id) })
      .unwrap()
      .then(() => {
        refetch();
      })
      .catch((error) => {
        console.error(error);
      });

    setDeleteQueue(deleteQueue.slice(1));
  };

  const handleConfirm = () => {
    confirmDelete();
    if (deleteQueue.length <= 1) {
      setShowConfirmationModal(false);
      setSelectedIds([]);
    }
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
    setDeleteQueue([]);
  };

  const searchVendorName = (vendorName: string) => {
    setFilter({ name: vendorName });
  };

  const handleVendorSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchVendorName(vendorToSearch);
  };

  const getConfirmationMessage = () => {
    const targetId = Number(deleteQueue[0]);

    const vendorToDelete = vendors.find((vendor) => vendor.id == targetId) || {
      name: "",
      address: [],
      numProjects: [],
    };

    const { name, address, numProjects } = vendorToDelete;

    return (
      <>
        <p
          style={{
            color: "var(--navy-1, #1C2655)",
            fontSize: "14px",
            fontWeight: "700",
          }}
        >
          Are you sure you want to delete {name}?
        </p>
        <p
          style={{
            color: "var(--navy-1, #1C2655)",
            fontSize: "14px",
          }}
        >
          Deleting a vendor is final and cannot be undone.
        </p>
      </>
    );
  };

  const columns: ColumnDef<Vendor>[] = [
    {
      header: "Name",
      cell(props) {
        return (
          <div
            className="vendorName"
            onClick={() => {
              localStorage.setItem("lastPage", window.location.href);
              navigate(`edit/${props.row.original.id}`);
            }}
          >
            <span className="vendorNameText">{props.row.original.name}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M13.8067 4.69354C14.0667 4.43354 14.0667 4.0002 13.8067 3.75354L12.2467 2.19354C12 1.93354 11.5667 1.93354 11.3067 2.19354L10.08 3.41354L12.58 5.91354M2 11.5002V14.0002H4.5L11.8733 6.6202L9.37333 4.1202L2 11.5002Z"
                fill="#1C2655"
              />
            </svg>
          </div>
        );
      },
    },
    {
      header: "Address",
      cell(props) {
        return (
          <div className="streetAddress">
            {props.row.original?.address || ""}
          </div>
        );
      },
    },
    {
      header: "Number of Projects",
      cell(props) {
        return (
          <div className="numOfProjects">
            {props.row.original.numProjects || ""}
          </div>
        );
      },
    },
  ];
  if (isLoading) {
    return <ScopeStackSpinner />;
  }

  interface Column {
    header: string;
    cell: (props: any) => JSX.Element;
  }

  const getSlideOutTable = (type, data) => {
    const column: Column[] = [
      {
        header: type,
        cell(props) {
          return props.row.original.name;
        },
      },
    ];

    return [
      {
        sectionTitle: "",
        sectionClass: "",
        sectionContent: (
          <aside>
            <DataTable data={data} columns={column} striped />
          </aside>
        ),
      },
    ];
  };

  return (
    <div className="vendorsTable">
      <SlideOutMenuSectioned
        show={isOpen}
        setShow={setIsOpen}
        menuHeader={`All Assigned ${slideOutContent.title}`}
        actionButtonText={null}
        onActionButtonClick={() => setIsOpen(false)}
        clearFields={null}
        onCancel={() => setIsOpen(false)}
        onClearFieldsClick={() => null}
        sectionsArray={slideOutContent.content}
      />
      <Card
        style={{
          padding: "10px",
          borderRadius: "4px",
          border: "1px solid var(--gray-3-stroke, #D7D7D7)",
          background: " var(--white, #FFF)",
          boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Card.Header
          style={{
            backgroundColor: "#fff",
            lineHeight: "unset",
            display: "block",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="accordianTitle" style={{ margin: "0" }}>
              Vendors
            </div>
            <Button className="regButton" onClick={() => navigate(`create`)}>
              Add a Vendor
            </Button>
          </div>
          <hr></hr>
          <div
            style={{
              display: "flex",
            }}
          >
            {selectedIds.length > 0 && (
              <Button
                style={{
                  marginRight: "10px",
                  alignSelf: "flex-end",
                  background: "unset",
                  color: "var(--teal-1, #418172)",
                  fontWeight: "700",
                  width: "11em",
                  height: "40px",
                  borderRadius: "4px",
                  border: "1px solid var(--gray-3-stroke, #D7D7D7)",
                }}
                onClick={handleDelete}
              >
                {selectedIds.length} Selected{" "}
                <span
                  style={{
                    borderLeft: "1px solid #D7D7D7",
                    height: "24px",
                    margin: "0 8px",
                  }}
                ></span>
                <FontAwesomeIcon style={{ color: "black" }} icon={faTrashCan} />
              </Button>
            )}
            <div style={{ width: "-webkit-fill-available" }}>
              <FormFieldLabel
                label="Search Vendors"
                className="searchBarTitle"
              />
              <Form
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleVendorSearch(e);
                  }
                }}
                onSubmit={handleVendorSearch}
              >
                <SearchField
                  placeholder="Search by vendor name"
                  onChange={(e: {
                    target: { value: SetStateAction<string> };
                  }) => {
                    setVendorToSearch(e.target.value);
                  }}
                  value={vendorToSearch}
                  onClick={() => {
                    setVendorToSearch("");
                    searchVendorName("");
                  }}
                  fullWidth
                />
              </Form>
            </div>
          </div>
        </Card.Header>

        <DataTable
          data={vendors ? vendors : [{ id: 0, name: "no vendors found" }]}
          columns={columns}
          selectable
          bordered
          striped
          hover
          totalPages={pageMeta.pageCount}
          totalRows={pageMeta.recordCount}
          currentPage={pageNumber}
          setCurrentPage={setPageNumber}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          paginationEnabled
          pageSizeEnabled
        />
      </Card>
      <ConfirmationModal
        title="Confirm Delete"
        message={getConfirmationMessage()}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={showConfirmationModal}
        onHide={handleCancel}
      />
    </div>
  );
};

export default Show;
