import {
  ListProjectGovernancesApiArg,
  V1LineOfBusinessResource,
  V1ProjectPhaseResource,
  useListProjectGovernancesQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { Governance, OptionType } from "../../types/Governance";
import { renderResourceName } from "@utils/helperFunctions";
import { getPhase, getRateData } from "./governanceHelpers";

export interface HookArgs {
  page?: ListProjectGovernancesApiArg["page"];
  include?: ListProjectGovernancesApiArg["include"];
  filter?: ListProjectGovernancesApiArg["filter"];
  lobs?: V1LineOfBusinessResource[] | [];
  projectPhases?: V1ProjectPhaseResource[] | [];
}

const useListGovernances = ({
  page,
  include,
  filter,
  lobs,
  projectPhases,
}: HookArgs) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading, isFetching, refetch } =
    useListProjectGovernancesQuery({
      slug: accountSlug,
      page,
      include,
      filter,
    });

  let governances: Governance[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  if (data?.data) {
    const projectResources = data?.included?.filter(
      (includedData) => includedData.type == "project-resources"
    );
    const resources = data?.included?.filter(
      (includedData) => includedData.type == "resources"
    );

    try {
      governances = data.data.map((governance) => {
        if (!governance.id) {
          throw new Error("Missing governance.id");
        }

        let resource: OptionType = {
          label: "Select Resource",
          value: 0,
        };

        let phase: OptionType = {
          label: "Select Phase",
          value: 0,
        };

        let lob: OptionType = {
          label: "Select Category",
          value: 0,
        };

        let foundProjectResource = projectResources?.find(
          (rr) =>
            governance?.relationships?.["project-resource"]?.data?.id == rr.id
        );
        if (foundProjectResource?.id) {
          resource = {
            label: renderResourceName(foundProjectResource, lobs),
            value: Number(foundProjectResource.id),
          };
        } else {
          let foundResource = resources?.find(
            (r) => governance?.relationships?.["resource"]?.data?.id == r.id
          );
          if (foundResource?.id) {
            resource = {
              label: renderResourceName(foundResource, lobs),
              value: Number(foundResource.id),
            };
          }
        }

        let foundLob = (lobs || []).find(
          (lob) =>
            lob.id == governance?.relationships?.["service-category"]?.data?.id
        );
        if (foundLob) {
          lob = {
            label:
              foundLob.attributes["nested-name"] ||
              foundLob.attributes.name ||
              "Select Category",
            value: foundLob.id || 0,
          };
        }

        let foundPhase = (projectPhases || []).find(
          (phase) =>
            phase.id == governance?.relationships?.["project-phase"]?.data?.id
        );
        if (foundPhase) {
          phase = {
            label: foundPhase?.attributes?.name || "Select Phase",
            value: foundPhase.id || 0,
          };
        }

        return {
          id: String(governance.id),
          description: governance?.attributes?.description || "",
          "deleted-at": governance?.attributes?.["deleted-at"] || "",
          resource: resource,
          phaseForDisplay: getPhase(governance, projectPhases),
          phase: phase,
          hours: Number(governance?.attributes?.hours) || 0,
          calculationType: governance?.attributes?.["calculation-type"],
          calculationTypeForDisplay:
            (getRateData(governance, projectPhases, lobs) as string) || "",
          allocationMethod: governance?.attributes?.["allocation-method"],
          assignEffortToService:
            governance?.attributes?.["assign-effort-to-service"],
          rate: governance?.attributes?.rate,
          filterId: governance?.attributes?.["filter-id"]?.toString() || null,
          filterType: governance?.attributes?.["filter-type"],
          lob: lob,
          position: governance?.attributes?.position,
          "project-variables": governance?.attributes?.[
            "project-variables"
          ].map((pv) => {
            return { ...pv, id: pv.uuid };
          }),
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    data: governances,
    governancesError: error || customError,
    governancesLoading: isLoading,
    governancesFetching: isFetching,
    pageMeta,
    refetch,
  };
};

export default useListGovernances;
