import React, { useState, useEffect } from "react";
import { useListExpenses } from "./api";
import DataTable from "@components/DataTable";
import {
  useDeleteProjectExpenseMutation,
  useListLineOfBusinessesForAccountQuery,
} from "@generated";
import CurrencyFormat from "react-currency-format";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { Expense } from "../types/Expense";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { ColumnDef } from "@tanstack/react-table";
import { Card, Row, Col, Button } from "react-bootstrap";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import "./styles/style.css";
import { useLocation, useNavigate } from "react-router";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";

const Show = ({ project_id, account, permission, project }): JSX.Element => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [selectedExpenseIds, setSelectedExpenseIds] = useState<string[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessages, setErrorMessages] = useState<string | string[]>(
    "Something went wrong! Your changes could not be saved at this time."
  );
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    "Project Expense saved successfully!"
  );
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [reloading, setReloading] = useState(false);

  // Bulk Delete States
  const [deleteExpense] = useDeleteProjectExpenseMutation();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteQueue, setDeleteQueue] = useState<string[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    data: lobs,
    isLoading: lobsLoading,
    isError: lobsErr,
  } = useListLineOfBusinessesForAccountQuery({ slug: accountSlug });

  const { expenses, expensesError, expensesLoading, pageMeta, refetch } =
    useListExpenses({
      page: {
        number: pageNumber,
        size: pageSize,
      },
      include: [
        "resource",
        "project-phase",
        "project-resource",
        "project",
        "expense-category",
        "project-service",
      ],
      lobs: lobs && lobs.data && !lobsLoading ? lobs.data : [],
      filter: { active: true, project: project_id },
    });

  useEffect(() => {
    window.history.replaceState({}, "");
    setReloading(true);
    refetch().then((res) => setReloading(false));
    if (location?.state?.edit) {
      setSuccessMessage("Project Expense updated successfully!");
      setShowSuccessAlert(true);
    }
    if (location?.state?.new) {
      setSuccessMessage("Project Expense created successfully!");
      setShowSuccessAlert(true);
    }
  }, []);

  const handleDelete = () => {
    setDeleteQueue([...selectedExpenseIds]);
    setShowConfirmationModal(true);
  };

  const confirmDelete = () => {
    setIsDeleting(true);
    if (deleteQueue.length === 0) return;

    const promises: any = [];
    deleteQueue.forEach((expenseIdToDelete) => {
      promises.push(
        deleteExpense({
          slug: accountSlug,
          id: parseInt(expenseIdToDelete),
        })
      );
    });

    Promise.all(promises)
      .then((res) => {
        setSuccessMessage(
          `Project Expense${
            deleteQueue.length > 1 ? "s" : ""
          } deleted successfully!`
        );
        setShowSuccessAlert(true);
        setIsDeleting(false);
        setShowConfirmationModal(false);
        setSelectedExpenseIds([]);
        setDeleteQueue([]);
        refetch();
      })
      .catch((error) => {
        setErrorMessages(
          "One or more expenses could not be deleted at this time. Please try again later."
        );
        setShowFailAlert(true);
      });
  };

  const handleConfirm = () => {
    confirmDelete();
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
    setDeleteQueue([]);
  };

  const getConfirmationMessage = () => {
    return (
      <>
        <div
          style={{
            color: "#1C2655",
            fontSize: "14px",
          }}
        >
          {isDeleting ? (
            <SmallSpinner />
          ) : (
            <span style={{ fontWeight: "700" }}>{`You are about to delete ${
              selectedExpenseIds.length
            } expense item${
              selectedExpenseIds.length > 1 ? "s" : ""
            }.`}</span>
          )}
          <ul style={{ marginTop: "10px" }}>
            {selectedExpenseIds.map((expenseIdToDelete) => {
              let expenseName = expenses.find(
                (expense) => expense.id == expenseIdToDelete
              )?.description;
              return <li>{expenseName}</li>;
            })}
          </ul>
          This action cannot be undone. Do you wish to proceed?
        </div>
      </>
    );
  };

  const formatCurrency = (price) => (
    <CurrencyFormat
      displayType="text"
      prefix={
        project?.data?.attributes?.["field-labels"]["currency_unit"]
          ? project.data.attributes["field-labels"]["currency_unit"]
          : "$"
      }
      isNumericString={true}
      thousandSeparator={true}
      value={(Number(price) || 0).toFixed(2)}
    />
  );

  const expenseColumns: ColumnDef<Expense>[] = [
    {
      header: "Expense",
      cell(props) {
        return (
          <div>
            {props.row.original.name}{" "}
            {props.row.original.id !== 0 && (
              <FontAwesomeIcon
                className="cursorPoint"
                icon={faPencil}
                onClick={() => {
                  navigate(
                    `/projects/${project_id}/project_expenses/edit/${props.row.original.id}`,
                    {
                      state: { expense: props.row.original },
                    }
                  );
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      header: "Resource",
      cell(props) {
        return props.row.original.resource?.label !== "Select Resource"
          ? props.row.original.resource?.label
          : "";
      },
    },
    {
      header: "Phase",
      cell(props) {
        return props.row.original.phase?.label !== "Assign to Phase"
          ? props.row.original.phase?.label
          : "";
      },
    },
    {
      header: "Quantity",
      cell(props) {
        return props.row.original.quantity;
      },
    },
    {
      header: "Unit Cost",
      cell(props) {
        return formatCurrency(props.row.original.unitCost);
      },
    },
    {
      header: "Total Cost",
      cell(props) {
        return formatCurrency(props.row.original.totalCost);
      },
    },
  ];

  if (permission === "none") {
    return (
      <div className="expensePage">
        You do not have permmission to view this page. Please contact your
        administrator for assistance.
      </div>
    );
  }

  return (
    <div className="expensePage">
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <Card>
        <Card.Header
          style={{
            backgroundColor: "#fff",
            color: "#1C2655",
            padding: "0px 16px",
          }}
        >
          <Row style={{ padding: "20px 0 0 20px", alignItems: "center" }}>
            <Col className="text24" sm={9}>
              Travel and Expenses
            </Col>
            {permission !== "view" &&
              project?.data?.attributes?.status === "building" && (
                <Col>
                  <Button
                    style={{ marginLeft: "60px" }}
                    className="squareGreenButton"
                    onClick={() => {
                      navigate(`/projects/${project_id}/project_expenses/new`, {
                        state: {},
                      });
                    }}
                  >
                    Add Travel / Expense
                  </Button>
                </Col>
              )}
          </Row>
        </Card.Header>
        <hr style={{ marginLeft: "30px", marginRight: "30px" }} />
        <Card.Body style={{ marginTop: "-10px" }}>
        {selectedExpenseIds.length > 0 ? (
            <Button
              className="deleteBox"
              onClick={handleDelete}
            >
              {selectedExpenseIds.length} Selected{" "}
              <span className="selectedSpan"></span>
              <FontAwesomeIcon style={{ color: "black" }} icon={faTrashCan} />
            </Button>
          ) : (
            <div className="multiDeletePlaceholder"></div>
          )}
          {!expenses || expensesLoading || !lobs || lobsLoading || reloading ? (
            <SmallSpinner />
          ) : (
            <DataTable
              classForTable="expenseTable"
              data={
                expenses?.length > 0
                  ? expenses
                  : [
                      {
                        id: 0,
                        name: "Add expenses to get started",
                      },
                    ]
              }
              columns={expenseColumns}
              selectable={
                expenses.length > 0 &&
                permission !== 'view' &&
                project?.data?.attributes?.status === 'building'
              }
              striped
              hover
              selectedIds={selectedExpenseIds}
              setSelectedIds={setSelectedExpenseIds}
              totalPages={pageMeta.pageCount}
              totalRows={pageMeta.recordCount}
              currentPage={pageNumber}
              setCurrentPage={setPageNumber}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              paginationEnabled
              pageSizeEnabled
            />
          )}
          {expenses.length > 0 && (
            <div style={{ marginLeft: "16px" }} className="text20">
              <strong>Subtotal:</strong>&nbsp;
              {formatCurrency(
                expenses
                  .map((e) => Number(e.totalCost || 0))
                  .reduce((acc, current) => acc + current, 0)
              )}
            </div>
          )}
           <ScopeStackModal
            modalTitle="Delete Expense"
            modalBody={getConfirmationMessage()}
            handleButton2Click={isDeleting ? () => null : handleConfirm}
            handleClose={handleCancel}
            show={showConfirmationModal}
            handleButton1Click={handleCancel}
            deleteModal={true}
            button1Text={"Cancel"}
            button2Text={"Delete"}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default Show;
