import { useEffect, useState } from "react";
import "../style/style";
import useListStandards from "./api/useListStandards";
import DataTable from "@components/DataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faSortDown,
  faSortUp,
  faSort,
} from "@fortawesome/pro-solid-svg-icons";
import { Button, Form } from "react-bootstrap";
import SearchField from "@components/Forms/SearchField/SearchField";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { ServicePreview } from "@components/PreviewSlideouts";
import { Service } from "../types";
import { useLocation } from "react-router";
import Select from "react-select";
import { ServicesTable } from "../../../Services/ServicesTable/ServicesTable";
import { useGetProjectByIdQuery } from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import {
  useListResourceRates,
  useListProjectResources,
  useListResources,
} from "../api";
import { renderResourceOptions } from "@utils/helperFunctions";
import useGetAccount from "../../../Account/EditAccount/api/useGetAccount";
import useOauth from "@utils/customHooks/useOauth";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { handleCreateProjectServices, formatResource } from "../common/helpers";
import { getOrganizedPhasesFromListOfServices } from "@utils/helpers";

const Standards = ({ project_id }) => {
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [authorizationCode] = useOauth();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serviceType =
    queryParams.get("service_type") === "managed_services"
      ? "managed_services"
      : "professional_services";

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedStandards, setSelectedStandards] = useState<Service[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterParams, setFilterParams] = useState<{
    service_type: "professional_services" | "managed_services";
    search?: string;
  }>({
    service_type: serviceType,
  });
  const [searchInput, setSearchInput] = useState<string>("");
  const [serviceToPreview, setServiceToPreview] = useState<Service>({
    id: "0",
    name: "",
  });
  const [slideoutOpen, setSlideoutOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState(
    "Something went wrong saving project services"
  );
  const [showFailAlert, setShowFailAlert] = useState(false);

  const [updating, setUpdating] = useState(false);

  //step 1 = select standards, step 2 = review & apply
  const [step, setStep] = useState<number>(1);

  const { data: project, isLoading: projectLoading } = useGetProjectByIdQuery({
    slug: accountSlug,
    id: Number(project_id),
    include: ["rate-table"],
  });

  const { data: account, isLoading: accountLoading } =
    useGetAccount(accountSlug);

  const rateTableId = project?.data?.relationships?.["rate-table"]?.data?.id;
  const resourceRatesEnabled =
    account?.attributes?.settings?.project_resource_rates;

  const { resourceRates, resourceRatesLoading } =
    useListResourceRates(rateTableId);

  const { resources, resourcesLoading } = useListResources();

  const { projectResources, projectResourcesLoading } =
    useListProjectResources(project_id);

  const { standards, isLoading, isFetching, pageMeta } = useListStandards({
    filterParams,
    pageSize,
    currentPage,
    sortBy,
    sortOrder,
  });

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchName();
  };

  const searchName = () => {
    setFilterParams({ ...filterParams, search: searchInput });
  };

  const handleSort = (columnId: string) => {
    if (sortBy === columnId) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnId);
      setSortOrder("asc");
    }
  };

  const renderSortIcon = (columnId: string) => {
    if (sortBy === columnId) {
      return sortOrder === "asc" ? (
        <FontAwesomeIcon icon={faSortUp} />
      ) : (
        <FontAwesomeIcon icon={faSortDown} />
      );
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const selectColumns = [
    {
      id: "name",
      header: () => (
        <div className="displayFlex">
          Standard Name
          <div className="sortIcon" onClick={() => handleSort("name")}>
            {renderSortIcon("name")}
          </div>
        </div>
      ),
      cell(props) {
        const item = props?.row?.original;
        return item?.id == -1 ? (
          "No standards found"
        ) : (
          <div className={item?.id == -1 ? "" : "projectServiceName"}>
            {item?.name}
          </div>
        );
      },
    },
    {
      id: "service category",
      header: () => (
        <div className="displayFlex">
          Service Category
          <div
            className="sortIcon"
            onClick={() => handleSort("service_category.nested_name")}
          >
            {renderSortIcon("service_category.nested_name")}
          </div>
        </div>
      ),
      cell(item) {
        return <div>{item?.row?.original?.category || ""}</div>;
      },
    },
    {
      id: "preview",
      header: () => "Preview",
      cell(props) {
        const item = props?.row?.original;
        return item?.id == -1 ? (
          ""
        ) : (
          <div className="previewService">
            <FontAwesomeIcon
              icon={faEye}
              onClick={() => {
                setServiceToPreview(item);
                setSlideoutOpen(true);
              }}
            />
          </div>
        );
      },
    },
    {
      id: "phase",
      header: () => (
        <div className="displayFlex">
          Phase
          <div className="sortIcon" onClick={() => handleSort("phase.name")}>
            {renderSortIcon("phase.name")}
          </div>
        </div>
      ),
      cell(item) {
        return <div>{item?.row?.original?.phase?.name || ""}</div>;
      },
    },
    {
      id: "total hours",
      header: () => "Total Hours",
      cell(item) {
        return (
          <div className="alignRight">{item?.row?.original?.hours || ""}</div>
        );
      },
    },
  ];

  const reviewColumns = [
    {
      id: "service name",
      header: () => "Service Name",
      cell(item) {
        return item.id !== "noRow" ? (
          <div className="serviceName">
            <Form.Control type="text" value={item.name} disabled={true} />
            {item.type === "services" && (
              <div className="previewService review">
                <FontAwesomeIcon
                  icon={faEye}
                  onClick={() => {
                    setServiceToPreview(item);
                    setSlideoutOpen(true);
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          item.name
        );
      },
    },
    {
      id: "total hours",
      header: () => "Total Hours",
      cell(item) {
        return (
          item.id !== "noRow" && (
            <Form.Control type="text" value={item.hours} disabled={true} />
          )
        );
      },
    },
    {
      id: "qty",
      header: () => "QTY",
      cell(item) {
        return (
          item.id !== "noRow" && (
            <Form.Control
              type="number"
              min="0"
              step="1"
              defaultValue={item.qty || 1}
              onBlur={(e) => {
                item = {
                  ...item,
                  qty: e.target.value,
                };
                const updated = [...selectedStandards].map((s) => {
                  if (item.type === "subservices") {
                    return {
                      ...s,
                      subservices:
                        s.subservices?.map((sub) =>
                          sub.id === item.id
                            ? { ...item, isChanged: true }
                            : sub
                        ) || [],
                    };
                  }

                  return s.id === item.id ? item : s;
                });
                setSelectedStandards(updated);
              }}
              onChange={(e) => {
                e.stopPropagation();
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          )
        );
      },
    },
    {
      id: "resource",
      header: () => "Resource",
      cell(item) {
        const service =
          item.type === "subservices"
            ? selectedStandards.find((s) => s.id === item?.serviceId)
            : undefined;

        //Use the defined resource if it exists, otherwise, if it is a subservice, default to service resource
        const resource = item?.resource
          ? {
              label: item.resource.name,
              value: item.resource.id,
            }
          : item.type === "subservices" && service?.resource
          ? {
              label: service.resource.name,
              value: service.resource.id,
            }
          : undefined;

        return (
          item?.id !== "noRow" && (
            <Select
              className="resourceDropdown"
              value={resource}
              options={renderResourceOptions(
                projectResources,
                resourceRatesEnabled ? resourceRates : resources,
                []
              )}
              onChange={(e) => {
                if (e) {
                  item = {
                    ...item,
                    resource: { id: e.value, name: e.label },
                  };
                  const updated = [...selectedStandards].map((s) => {
                    if (item.type === "subservices") {
                      return {
                        ...s,
                        subservices:
                          s.subservices?.map((sub) =>
                            sub.id === item.id
                              ? { ...item, isChanged: true }
                              : sub
                          ) || [],
                      };
                    }

                    return s.id === item.id ? item : s;
                  });

                  setSelectedStandards(updated);
                }
              }}
            />
          )
        );
      },
    },
  ];

  useEffect(() => {
    const cart = [...selectedStandards];
    selectedIds.forEach((id) => {
      const standard = standards.find((s) => String(s.id) === id);
      const standardInCart = cart.find((s) => String(s.id) === id);
      if (standard && !standardInCart) {
        cart.push(standard);
      }
    });
    setSelectedStandards(
      cart.filter((s) => selectedIds.includes(String(s.id)))
    );
  }, [selectedIds]);

  const handleFailAlert = (msg) => {
    setErrorMessage(msg || "Something went wrong");
    setShowFailAlert(true);
  };

  const loading =
    isLoading ||
    isFetching ||
    projectLoading ||
    accountLoading ||
    resourcesLoading ||
    resourceRatesLoading ||
    projectResourcesLoading ||
    updating;

  const count = selectedIds.length ? `(${selectedIds.length})` : "";

  const phases =
    step == 1 ? [] : getOrganizedPhasesFromListOfServices(selectedStandards);

  return (
    <div className="servicesV2">
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <div className="actionBtns">
        <div className="leftHeader">
          {" "}
          <Button
            className="button cancelButton"
            onClick={(e) => {
              if (step === 1) {
                window.location.replace(
                  `${appHost}/projects/${project_id}/${
                    serviceType === "professional_services"
                      ? "project_editor"
                      : "managed_service"
                  }`
                );
              } else {
                setStep(1);
              }
            }}
          >
            {step === 1 ? "Cancel" : "Back"}
          </Button>
          <div className="servicesH1">Add Services from Standards</div>
        </div>{" "}
        <Button
          className={`button ${selectedIds.length < 1 ? "disabled" : ""}`}
          onClick={async () => {
            if (step === 1) {
              setStep(2);
            } else {
              if (
                serviceType === "professional_services" &&
                selectedStandards.some((s) => !formatResource(s))
              ) {
                handleFailAlert(
                  "Please select a resource for each service to continue"
                );
                return;
              }
              setUpdating(true);
              await handleCreateProjectServices(
                selectedStandards,
                serviceType,
                project_id,
                accountSlug,
                authorizationCode,
                handleFailAlert
              );

              window.location.replace(
                `${appHost}/projects/${project_id}/${
                  serviceType === "professional_services"
                    ? "project_editor"
                    : "managed_service"
                }`
              );
            }
          }}
          disabled={
            (step == 1 && selectedIds.length < 1) || (step === 2 && updating)
          }
        >
          {step === 1
            ? `Continue to Add ${count}`
            : `Apply ${count} Standard${selectedIds.length > 1 ? "s" : ""}`}
        </Button>
      </div>
      <div className="servicesH2">
        {step === 1
          ? "1. Select desired Standard(s) to continue."
          : "2. Review & apply selected Standard(s)."}
      </div>
      {step === 1 && (
        <div className="searchField">
          <FormFieldLabel label="Search Standards" className={undefined} />
          <Form
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setCurrentPage(1);
                handleSearch(e);
              }
            }}
            onSubmit={(e) => {
              setCurrentPage(1);
              handleSearch(e);
            }}
          >
            <SearchField
              placeholder="Search existing standards by name, service category, or phase"
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              value={searchInput}
              onClick={(e) => {
                setSearchInput("");
                setFilterParams({ ...filterParams, search: undefined });
              }}
              fullWidth
            />
          </Form>
        </div>
      )}

      {loading ? (
        <SmallSpinner />
      ) : step === 1 ? (
        <DataTable
          data={
            standards?.length
              ? standards
              : [{ id: "-1", name: "No standards found" }]
          }
          columns={
            serviceType === "professional_services"
              ? selectColumns
              : selectColumns.filter((column) => column.id !== "phase")
          }
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          totalRows={pageMeta?.recordCount}
          totalPages={pageMeta?.pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={(num) => setPageSize(num)}
          paginationEnabled
          pageSizeEnabled
          selectable={standards?.length > 0}
          bordered
          striped
          hover
        />
      ) : (
        phases.map((p, i) => (
          <div key={i} className="phaseContainer">
            <div className="phaseHeader">{p?.name || ""}</div>
            <Form>
              <ServicesTable
                servicesPassed={
                  selectedStandards?.filter((s) => s?.phase?.id === p?.id) || [
                    { id: "0", name: "No services found" },
                  ]
                }
                onUpdateServices={(services) => null}
                serviceColumns={
                  serviceType === "professional_services"
                    ? reviewColumns
                    : reviewColumns.filter((column) => column.id !== "resource")
                }
                subserviceColumns={
                  serviceType === "professional_services"
                    ? reviewColumns
                    : reviewColumns.filter((column) => column.id !== "resource")
                }
              />
            </Form>
          </div>
        ))
      )}
      <ServicePreview
        service={serviceToPreview}
        slideoutOpen={slideoutOpen}
        setSlideoutOpen={setSlideoutOpen}
        isManagedService={serviceType === "managed_services"}
      />
    </div>
  );
};

export default Standards;
