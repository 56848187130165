import { Routes, Route, Outlet } from "react-router-dom";
import Show from "./Show";
import New from "./New";
import Edit from "./Edit";

function ProjectSurveysMspa(props) {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route index element={<Show {...props} />} />
      </Route>
      <Route path="/new" element={<Outlet />}>
        <Route index element={<New {...props} />} />
      </Route>
      <Route path="/:id" element={<Outlet />}>
        <Route index element={<Edit {...props} />} />
      </Route>
      <Route path="/take" element={<Outlet />}>
        <Route
          index
          element={<Show {...props} hideCompletedSurveys={true} />}
        />
      </Route>
    </Routes>
  );
}

export default ProjectSurveysMspa;
