import React from "react";
import useListProjectResourcesQuery from "./api/useListProjectResourcesQuery";
import ResourcesV2 from "./ResourcesV2";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import {
  useGetProjectByIdQuery,
  useListLineOfBusinessesForAccountQuery,
  useListResourceRatesForAccountQuery,
  useListResourcesForAccountQuery,
} from "@generated";
import useWhoAmI from "../Account/EditAccount/api/useWhoAmI";

function ResourcesV2Mspa({ account_slug, project_id }) {
  const { data: project, isLoading: projectLoading } = useGetProjectByIdQuery({
    slug: account_slug,
    id: project_id,
    include: ["account", "rate-table"],
  });

  const { apiProjectResources, error, isLoading, refetch } =
    useListProjectResourcesQuery({
      useResourceRates: false,
      project_id: project_id,
    });

  const { data: resources } = useListResourcesForAccountQuery({
    slug: account_slug,
  });

  const { data: lobs } = useListLineOfBusinessesForAccountQuery({
    slug: account_slug,
  });

  const { data: user, error: userError, isLoading: userLoading } = useWhoAmI();

  const { data: resourceRates } = useListResourceRatesForAccountQuery({
    slug: account_slug,
    include: ["line-of-business", "resource"],
    filter: {
      "rate-table":
        project?.data?.relationships?.["rate-table"]?.["data"]?.[
          "id"
        ]?.toString(),
    },
    page: {
      size: 1000
    },
  });

  if (
    isLoading ||
    userLoading ||
    !lobs ||
    !project ||
    !resources ||
    !resourceRates
  ) {
    return <ScopeStackSpinner />;
  }

  const account = project?.included?.[0];
  return (
    <ResourcesV2
      apiProjectResources={apiProjectResources}
      project={project?.data}
      lobs={lobs?.data}
      user={user}
      account_slug={account_slug}
      resources={resources?.data}
      resourceRates={resourceRates?.data}
      useResourceRates={true}
    />
  );
}

export default ResourcesV2Mspa;
