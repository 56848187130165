import { useGetVendorQuery } from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { Vendor } from "../types";

const useGetVendor = (vendorId: number) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading } = useGetVendorQuery({
    slug: accountSlug,
    id: vendorId,
  });

  let vendor: Vendor | null = null;
  let customError: string | null = null;

  if (data?.data && data.data.id) {
    try {
      vendor = {
        id: data.data?.id || 0,
        type: data.data.type,
        attributes: {
          name: data.data.attributes?.name || "",
          streetAddress: data.data.attributes?.["street-address"] || "",
          street2: data.data.attributes?.street2 || "",
          city: data.data.attributes?.city || "",
          state: data.data.attributes?.state || "",
          postalCode: data.data.attributes?.["postal-code"] || "",
          country: data.data.attributes?.country || "",
        },
        relationships: {
          account: data.data.relationships?.account || { data: null },
        },
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return { vendor, error: error || customError, isLoading };
};

export default useGetVendor;
