import React from "react";
import { useAuthorization } from "@common/providers";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { Route, Routes } from "react-router";
import Show from "./Show";
import Edit from "./Edit/Edit";
import New from "./New/New";
import {
  useGetAccountQuery,
  useGetProjectByIdQuery,
  useWhoAmIQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import useListProjectVariables from "../ProjectVariables/api/useListProjectVariables";

const GovernanceV2Mspa = ({ project_id }): JSX.Element => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  // Get Account
  const {
    data: account,
    error: accountError,
    isLoading: accountLoading,
    refetch: accountRefetch,
  } = useGetAccountQuery({
    slug: accountSlug,
    include: ["phases", "resources"],
  });

  // Get Project with included items
  const {
    data: project,
    error: projectError,
    isLoading: projectLoading,
    refetch: refetchProjectData,
  } = useGetProjectByIdQuery({
    id: project_id,
    slug: accountSlug,
    include: [
      "project-phases",
      "payment-term",
      "project-resources",
      "project-services",
      "rate-table",
    ],
  });

  // Get permissions for current user
  const {
    data: currentUser,
    error: currentUserError,
    isLoading: currentUserLoading,
    refetch: currentUserRefetch,
  } = useWhoAmIQuery();

  const { allProjectVariables, isLoading: projectVariablesLoading } =
    useListProjectVariables("governance");

  const authorized = useAuthorization();
  if (
    !authorized ||
    !account ||
    accountLoading ||
    !currentUser ||
    currentUserLoading ||
    !project ||
    projectLoading ||
    projectVariablesLoading
  ) {
    return <ScopeStackSpinner />;
  }

  const permission =
    currentUser?.data?.attributes?.privileges?.["projects.projects_governance"];
  const projectPhases =
    project?.included?.filter((item) => item.type === "project-phases") || [];

  return (
    <div className="tab-content">
      <div className="tab-pane active">
        <Routes>
          <Route
            path="/"
            element={
              <Show
                project_id={project_id}
                account={account}
                permission={permission}
                project={project}
                projectPhases={projectPhases}
              />
            }
          />
          <Route
            path="/edit/:governanceId"
            element={
              <Edit
                project_id={project_id}
                account={account}
                permission={permission}
                project={project}
                projectPhases={projectPhases}
                projectVariables={allProjectVariables}
              />
            }
          />
          <Route
            path="/new"
            element={
              <New
                project_id={project_id}
                account={account}
                permission={permission}
                project={project}
                projectPhases={projectPhases}
                projectVariables={allProjectVariables}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default GovernanceV2Mspa;
