import {
  ListProductsForAccountApiArg,
  V1PagingMeta,
  V1Relationships,
  useListProductsForAccountQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { Product } from "../types";

export interface HookArgs {
  page?: ListProductsForAccountApiArg["page"];
  filter?: ListProductsForAccountApiArg["filter"];
}

const useListProductsForAccount = ({ page, filter }: HookArgs) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading, refetch } = useListProductsForAccountQuery({
    slug: accountSlug,
    page,
    filter,
  });

  let products: Product[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  if (data?.data) {
    try {
      products = data.data.map((product) => {
        if (!product.id) {
          throw new Error("Missing product.id");
        }

        return {
          id: product.id,
          name: product.attributes?.name || "",
          description: product.attributes?.description || "",
          intProdID: product.attributes?.["product-id"] || "",
          unitPrice: product.attributes?.["unit-price"] || 0,
          units: product.attributes?.["unit-of-measure"] || "",
          mfgPartNum: product.attributes?.["manufacturer-part-number"] || "",
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    products,
    error: error || customError,
    isLoading,
    pageMeta,
    refetch,
  };
};

export default useListProductsForAccount;
