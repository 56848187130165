import {
  ListVendorsForAccountApiArg,
  V1PagingMeta,
  V1Relationships,
  useListVendorsForAccountQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { Vendor } from "../types";

export interface HookArgs {
  page?: ListVendorsForAccountApiArg["page"];
  filter?: ListVendorsForAccountApiArg["filter"];
}

const useListVendorsOnAccount = ({ page, filter }: HookArgs) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading, refetch } = useListVendorsForAccountQuery({
    slug: accountSlug,
    include: ["account"],
    page,
    filter,
  });

  let vendors: Vendor[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  if (data?.data) {
    try {
      vendors = data.data.map((vendor) => {
        if (!vendor.id) {
          throw new Error("Missing vendor.id");
        }

        return {
          id: vendor?.id || 0,
          type: vendor?.type || "",
          attributes: {
            name: vendor.attributes?.name || "",
            streetAddress: vendor.attributes?.["street-address"] || "",
            street2: vendor.attributes?.street2 || "",
            city: vendor.attributes?.city || "",
            state: vendor.attributes?.state || "",
            postalCode: vendor.attributes?.["postal-code"] || "",
            country: vendor.attributes?.country || "",
          },
          relationships: {
            account: vendor.relationships?.account ?? null,
            quotes: vendor.relationships?.quotes ?? [],
          },
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return { vendors, error: error || customError, isLoading, pageMeta, refetch };
};

export default useListVendorsOnAccount;
