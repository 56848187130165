import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import useGetAccount from "../../Teams/common/api/useGetAccount";
import FormTextField from "@components/Forms/FormTextField";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { handleAlert } from "@utils/helperFunctions";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { RootState } from "@reducers/rootReducer";
import {
  useCreateVendorMutation,
  useUpdateVendorMutation,
  V1VendorResource,
  V1ToManyRelationships,
} from "@generated";
import { Vendor } from "./types";
import "../style.css";
import CountryPicker from "../../../../components/Forms/CountryPicker";

interface VendorInfoProps {
  mode: "create" | "edit";
  vendor: Vendor;
  setVendorAttributes: (vendor) => void;
  refetch?: () => void;
  onSubmit: (nextAction: "edit" | "new") => void;
}

const VendorInfo: React.FC<VendorInfoProps> = ({
  mode,
  vendor,
  setVendorAttributes,
  onSubmit,
  refetch = () => {},
}) => {
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const navigate = useNavigate();
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { account } = useGetAccount();

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showFailAlert, setShowFailAlert] = useState(false);

  const [createVendor] = useCreateVendorMutation();
  const [updateVendor] = useUpdateVendorMutation();

  const formatVendorData = (
    data: Vendor,
    accountId: number
  ): V1VendorResource => {
    const countryData =
      typeof data.attributes.country === "string"
        ? data.attributes.country
        : data.attributes.country?.id || "";

    return {
      id: Number(data.id),
      type: "vendors",
      attributes: {
        name: data.attributes.name,
        "street-address": data.attributes.streetAddress || "",
        street2: data.attributes.street2 || "",
        city: data.attributes.city || "",
        state: data.attributes.state || "",
        "postal-code": data.attributes.postalCode || "",
        country: countryData,
      },
      relationships: {
        account: {
          data: {
            id: Number(accountId),
            type: "accounts",
          },
        },
      },
    };
  };

  const handleFailResponse = (msg: string) => {
    setLoading(false);
    setErrorMessage(msg || "Something went wrong.");
    setShowFailAlert(true);
  };

  const handleUpdateVendor = () => {
    setLoading(true);

    if (!account?.id) {
      handleFailResponse("Account must exist to create a vendor.");
      return;
    }

    const data = formatVendorData(vendor, account.id);

    updateVendor({ slug: accountSlug, id: Number(data.id), body: { data } })
      .unwrap()
      .then((res) => {
        const id = res?.data?.id;
        const updatedVendor = res?.data;
        if (updatedVendor) {
          setSuccessMessage("Vendor updated successfully!");
          setShowSuccessAlert(true);
          setTimeout(() => {
            refetch();
          }, 200);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);

        handleFailResponse(
          error?.data?.errors?.[0]?.detail || "An error occured."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveVendor = (nextAction: "edit" | "new") => {
    setLoading(true);

    if (!account?.id) {
      handleFailResponse("Account must exist to create a vendor.");
      return;
    }

    const data = formatVendorData(vendor, account.id);
    delete data.id;

    const newVendorData: V1VendorResource = {
      type: "vendors",
      attributes: {
        name: vendor.attributes.name,
        "street-address": vendor.attributes?.["street-address"] || "",
        street2: vendor.attributes?.street2 || "",
        city: vendor.attributes?.city || "",
        state: vendor.attributes?.state || "",
        "postal-code": vendor.attributes?.["postal-code"] || "",
        //@ts-ignore
        country: vendor.attributes?.country?.id || "",
      },
      relationships: {
        account: {
          data: {
            type: "accounts",
            id: account.id,
          },
        },
      },
    };

    createVendor({ slug: accountSlug, body: { data: newVendorData } })
      .unwrap()
      .then((res) => {
        const id = res?.data?.id;
        if (id) {
          if (nextAction === "edit") {
            navigate(`/admin/vendors/edit/${id}`);
          } else if (nextAction === "new") {
            setVendorAttributes({
              id: 0,
              type: "vendors",
              attributes: {
                name: "",
                streetAddress: "",
                street2: "",
                city: "",
                state: "",
                postalCode: "",
                country: "",
              },
              relationships: {
                account: null,
              },
            });
          }
        } else {
          handleFailResponse("Failed to create vendor.");
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        handleFailResponse(
          error?.data?.errors?.[0]?.detail || "An error occurred."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderVendorForm = () => (
    <Form>
      <Card.Title style={{ color: "#1c2655", padding: "10px 0px" }}>
        Vendor Info
      </Card.Title>
      <FormTextField
        id="name"
        label="*Vendor Name"
        value={vendor.attributes.name}
        placeholder="Enter Vendor Name"
        onChange={(e) => {
          const newValue = e.target.value;
          setVendorAttributes((vendor) => ({
            ...vendor,
            attributes: { ...vendor.attributes, name: newValue },
          }));
        }}
        required={true}
        readOnly={false}
      />

      <Card.Title style={{ color: "#1c2655", padding: "10px 0px" }}>
        Contact Info
      </Card.Title>

      {Object.entries(vendor.attributes)
        .filter(([key]) => key === "streetAddress" || key === "street2")
        .map(([key, value]) => {
          const labelMap: Record<string, string> = {
            streetAddress: "Street Address",
            street2: "Address Line 2",
          };

          return (
            <FormTextField
              key={key}
              id={key}
              label={labelMap[key]}
              value={value}
              placeholder={`Enter ${labelMap[key]}`}
              onChange={(e) =>
                setVendorAttributes({
                  ...vendor,
                  attributes: { ...vendor.attributes, [key]: e.target.value },
                })
              }
              required={false}
              readOnly={false}
            />
          );
        })}

      <Row>
        {["city", "state", "postalCode"].map((key) => (
          <Col md={3} key={key}>
            <FormTextField
              id={key}
              label={
                {
                  city: "City",
                  state: "St/Prov/Terr",
                  postalCode: "Zip/Post",
                }[key]
              }
              value={
                vendor.attributes[key as keyof typeof vendor.attributes] || ""
              }
              placeholder={`Enter ${
                {
                  city: "City",
                  state: "St/Prov/Terr",
                  postalCode: "Zip/Post",
                }[key]
              }`}
              onChange={(e) =>
                setVendorAttributes({
                  ...vendor,
                  attributes: { ...vendor.attributes, [key]: e.target.value },
                })
              }
              required={false}
              readOnly={false}
            />
          </Col>
        ))}
        <Col md={3} className="country-picker-container">
          <label style={{ fontWeight: "bold" }}>Country</label>

          <div className="country-picker-wrapper">
            <CountryPicker
              country={vendor.attributes.country || null}
              onCountryChange={(selectedCountry) => {
                setVendorAttributes((prevVendor) => ({
                  ...prevVendor,
                  attributes: {
                    ...prevVendor.attributes,
                    country: selectedCountry,
                  },
                }));
              }}
            />
          </div>
        </Col>
      </Row>
    </Form>
  );

  return (
    <>
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <Card
        style={{
          padding: "16px",
          borderRadius: "4px",
          border: "1px solid var(--gray-3-stroke, #D7D7D7)",
          background: "var(--white, #FFF)",
          boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Card.Header
          style={{
            backgroundColor: "#fff",
            lineHeight: "40px",
            display: "block",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              className="accordianTitle"
              style={{ height: "40px", margin: "0", alignContent: "center" }}
            >
              <Button
                className="cancelButton"
                variant="secondary"
                onClick={() =>
                  window.location.replace(`${appHost}/admin/vendors`)
                }
              >
                Cancel
              </Button>
              {mode === "edit"
                ? `Edit ${vendor.attributes.name}`
                : "Add a Vendor"}
            </div>

            <div className="actionBtns">
              {mode === "create" && (
                <Button
                  className="cancelButton"
                  onClick={() => handleSaveVendor("new")}
                  disabled={loading}
                  variant="secondary"
                >
                  {loading ? <SmallSpinner /> : "Save and Add Another"}
                </Button>
              )}

              <Button
                className="regButton"
                onClick={() =>
                  mode === "edit"
                    ? handleUpdateVendor()
                    : handleSaveVendor("edit")
                }
                disabled={loading}
                variant="primary"
              >
                {loading ? <SmallSpinner /> : "Save"}
              </Button>
            </div>
          </div>
          <hr />
        </Card.Header>
        <Card.Body style={{ padding: "0px 16px 16px 16px" }}>
          {renderVendorForm()}
        </Card.Body>
      </Card>
    </>
  );
};

export default VendorInfo;
