import { useState, useEffect, useRef } from "react";
import { Button, Form, Row, Col, Tab, Tabs, FormCheck } from "react-bootstrap";
import DataTable from "@components/DataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import {
  AccountConnection,
  AuthField,
  IntegrationLog,
  ConnectionCategory,
} from "../types/connection";
import { ColumnDef } from "@tanstack/react-table";
import { formatDateAndTime } from "@utils/helpers";
import {
  useUpdateAccountConnectionMutation,
  useWhoAmIQuery,
  useReconnectAccountConnectionMutation,
  useEnableAccountConnectionMutation,
  useDisableAccountConnectionMutation,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import { useListIntegrationLogs, useGetConnectionById } from "./api";
import { useLocation, useNavigate } from "react-router";
import "../Show/styles/style.css";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { normalizeCasing, normalizeAppName } from "../common";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import {
  faCheckCircle,
  faCircleXmark,
} from "@fortawesome/pro-regular-svg-icons";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import useOauth from "@utils/customHooks/useOauth";
import API from "../../../../utils/API/API";
import FolderTreeComponentModal from "@components/FolderTreeComponent/FolderTreeComponentModal";

const Manage = (): JSX.Element => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [authorizationCode] = useOauth();
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname.split("/");
  const id = url[url.length - 1];

  const [updateConnection] = useUpdateAccountConnectionMutation();
  const [reconnectConnection] = useReconnectAccountConnectionMutation();
  const [enableConnection] = useEnableAccountConnectionMutation();
  const [disableConnection] = useDisableAccountConnectionMutation();

  const { connection, connectionLoading, connectionFetching, refetch } =
    useGetConnectionById(id);
  const { data: user, isLoading, isError } = useWhoAmIQuery();

  const connectedApp = location.state;
  const isOAuth = connectedApp?.authorization?.type;
  const isFileStorage = connection?.service === "merge_dev";

  const [fields, setFields] = useState<AuthField[]>([]);
  const [categories, setCategories] = useState<ConnectionCategory[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentTab, setCurrentTab] = useState(1);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [updateTriggered, setUpdateTriggered] = useState<boolean>(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [activating, setActivating] = useState(false);
  const checkConnectionStatusRef = useRef(true);
  const [showFileTreeModal, setShowFileTreeModal] = useState(false);

  const [selectedFolder, setSelectedFolder] = useState({ id: "0", name: "" });
  const [folderLoading, setFolderLoading] = useState(true);
  const [selectedConnection, setSelectedConnection] =
    useState<null | AccountConnection>(null);
  const [selectedIntegration, setSelectedIntegration] = useState({
    connection: { ...connection },
    provider: connectedApp?.providers?.find((prov) =>
      prov.name.toLowerCase().includes(connection?.settings?.["storage"])
    ),
  });

  const {
    integrationLogs,
    integrationLogsLoading,
    pageMeta,
    integrationLogsError,
  } = useListIntegrationLogs({
    page: {
      number: pageNumber,
      size: pageSize,
    },
    connectionId: id,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    refetch();
  }, []);

  useEffect(() => {
    if (connection && authorizationCode && !connectionLoading) {
      setSelectedConnection(connection);
      const getConnectionValue = (field) => {
        const getIndex = (obj, i) => obj?.[i] || "";
        return field.path.split(".").slice(1).reduce(getIndex, connection);
      };

      setFields(
        connectedApp.authorization.fields.map((field) => {
          const value = getConnectionValue(field);
          return { ...field, value: value || "" };
        })
      );

      setCategories(
        connectedApp.categories.map((c) => {
          const fields = c.fields.map((field) => {
            const value = getConnectionValue(field);
            return { ...field, value: value || "" };
          });
          return {
            ...c,
            checked: connection[c.category.toLowerCase()] || false,
            fields,
          };
        })
      );
    }
  }, [connectionLoading, authorizationCode]);

  if (connectionLoading || !connectedApp) return <ScopeStackSpinner />;

  const connectionsTableColumns: ColumnDef<AccountConnection>[] = [
    {
      header: "Status",
      cell(props) {
        const status = props.row.original.status;
        let color = "#8F8F8F";
        if (status == "Connected") color = "#63C4AB";
        if (status === "Connection Error") color = "#B90200";
        return (
          <>
            {status && (
              <FontAwesomeIcon
                icon={faCircle}
                style={{ color, marginRight: "5px" }}
              />
            )}
            {status}
            {status === "Connection Error" && (
              <div className="viewActivity" onClick={(e) => setCurrentTab(3)}>
                (View Activity)
              </div>
            )}
          </>
        );
      },
    },
    {
      header: "Last Sync",
      cell(props) {
        const date = props.row.original.lastSync;
        return <>{date ? formatDateAndTime(new Date(date)) : ""}</>;
      },
    },
    {
      header: "Additional Information",
      cell(props) {
        return <>{props.row.original.statusInfo}</>;
      },
    },
  ];
  if (isFileStorage) {
    connectionsTableColumns.splice(1, 0, {
      header: "Provider",
      cell(props) {
        return <>{normalizeAppName(props.row.original.host)}</>;
      },
    });
  }
  const integrationActivityTableColumns: ColumnDef<IntegrationLog>[] = [
    {
      header: "Time",
      cell(props) {
        if (props.row.original.time == "No integration activity found") {
          return <>{props.row.original.time}</>;
        }
        const date = props.row.original.time;
        return <>{date ? formatDateAndTime(new Date(date)) : ""}</>;
      },
    },
    {
      header: "Activity",
      cell(props) {
        return <div className="activityTd">{props.row.original.activity}</div>;
      },
    },
  ];

  const connectionName = normalizeAppName(connection?.service);
  const isConnected = connection?.status === "Connected";

  const buildConnectedAppForm = (field, index) => {
    return (
      <Col key={index} sm={6}>
        {field.input === "checkbox" ? (
          <div className="authSelect">
            <FormCheck
              id={field.label}
              key={field.label}
              checked={field.value === "1"}
              inline
              onChange={(e) => {
                const checked = e.target.checked;
                setFields(
                  fields.map((f) =>
                    field.label == f.label
                      ? {
                          ...f,
                          value: checked ? "1" : "0",
                        }
                      : f
                  )
                );
              }}
              disabled={isConnected}
            />
            <div className={`selectInfo ${field?.help ? "help" : ""}`}>
              <div>{field.label}</div>
              {field?.help && <span className="helpText">{field.help}</span>}
            </div>
          </div>
        ) : (
          <Form.Group className="formGroup">
            <FormFieldLabel className={""} label={field.label} />
            <Form.Control
              type={field.input}
              placeholder={`Enter ${field.label}`}
              defaultValue={field.value}
              onChange={(e) =>
                setFields(
                  fields.map((f) =>
                    field.label == f.label
                      ? {
                          ...f,
                          value: e.target.value,
                        }
                      : f
                  )
                )
              }
              required
              readOnly={isConnected}
            />
            {field?.help && <span className="helpText">{field.help}</span>}
          </Form.Group>
        )}
      </Col>
    );
  };

  const handleFailResponse = (msg) => {
    setActivating(false);
    setErrorMessage(
      `Uh oh, something went wrong! ${
        msg || "Check your credentials and try again."
      }`
    );
    setShowErrorModal(true);
  };

  const handleDisable = () => {
    if (!connection?.id) {
      handleFailResponse("");
      return;
    }

    disableConnection({
      slug: accountSlug,
      id: connection.id,
      body: { data: { id: connection.id } },
    })
      .then((res) => {
        //@ts-ignore
        if (res?.data?.data?.id) {
          setSuccessMessage("Connection disabled");
          setShowSuccessAlert(true);
          refetch();
        }
      })
      .catch((error) => {
        handleFailResponse(error?.data?.errors?.[0]?.detail);
      });
  };

  const checkConnectionStatus = (connectionId, checkOnce) => {
    API.Get(
      `${apiHost}/${accountSlug}/v1/account-connections/${connectionId}`,
      authorizationCode
    )
      .then((response) => {
        const status = response?.data?.data?.attributes?.status;
        if (status === "Activating") {
          if (checkOnce) {
            handleFailResponse("An error occured connecting this integration.");
            return;
          }

          // If status hasn't changed, wait 2 seconds and check again
          if (checkConnectionStatusRef.current) {
            setTimeout(() => {
              checkConnectionStatus(connectionId, false);
            }, 2000);
          } else {
            setActivating(false);
          }
        } else if (status === "Connected") {
          setActivating(false);
          setShowSuccessModal(true);
          refetch();
        } else if (status === "Connection Error") {
          setActivating(false);
          handleFailResponse(
            `A ${connectionName} connection error has occurred.`
          );
          refetch();
        } else {
          setActivating(false);
          handleFailResponse("");
          refetch();
        }
      })
      .catch((err) => {
        if (isFileStorage) {
          setTimeout(() => {
            checkConnectionStatus(connectionId, false);
          }, 2000);
        }
        handleFailResponse(err?.data?.errors?.[0]?.detail);
      });
  };

  const handleEnable = (reconnect: boolean) => {
    if (!connection?.id) {
      handleFailResponse("");
      return;
    }

    if (!reconnect) {
      enableConnection({
        slug: accountSlug,
        id: connection.id,
        body: { data: { id: connection.id } },
      })
        .then((res) => {
          //@ts-ignore
          const status = res?.data?.data?.attributes?.status;
          if (status === "Connected") {
            setSuccessMessage("Connection enabled");
            setShowSuccessAlert(true);
            refetch();
          } else if (status === "Connection error") {
            setErrorMessage(
              `A ${connectionName} connection error has occurred.`
            );
            setShowFailAlert(true);
            refetch();
          } else {
            handleFailResponse("Unable to enable connection.");
          }
        })
        .catch((error) => {
          handleFailResponse(error?.data?.errors?.[0]?.detail);
        });
    } else {
      let data = {
        id: connection.id,
        type: "account-connections",
        attributes: { settings: { ...connection?.settings } },
      };

      //Update authorization field attributes
      fields.forEach((field) => {
        const source = field.path.split(".").slice(1, 2)[0]; // "credentials" | "settings" | "mappings"
        const attribute = field.path.split(".").pop() as string;

        if (!data.attributes[source]) {
          data.attributes[source] = { [attribute]: field.value };
        } else {
          data.attributes[source] = {
            ...data.attributes[source],
            [attribute]: field.value,
          };
        }
      });

      //PATCH connection, then reconnect
      updateConnection({
        slug: accountSlug,
        id: connection.id,
        body: { data },
      })
        .unwrap()
        .then((res) => {
          if (res?.data?.id) {
            const oAuthURL = res.data.attributes?.["oauth-url"];
            const mergeURL = res?.data?.attributes?.["merge-auth-url"];

            reconnectConnection({
              slug: accountSlug,
              id: connection.id,
              body: { data: { id: connection.id } },
            })
              .then((res) => {
                //@ts-ignore
                const connectionId = res?.data?.data?.id;
                if (connectionId) {
                  if (oAuthURL || mergeURL) {
                    const windowFeatures = `width=600,height=600,left=${
                      (window.screen.width - 600) / 2
                    },top=${(window.screen.height - 600) / 2},resizable=yes`;

                    const authWindow = window.open(
                      oAuthURL || mergeURL,
                      "_blank",
                      windowFeatures
                    );

                    if (!authWindow)
                      handleFailResponse("Please enter a valid host.");

                    const checkOAuthCallback = setInterval(() => {
                      if (authWindow?.closed) {
                        clearInterval(checkOAuthCallback);
                        checkConnectionStatus(connection.id, true);
                      }
                    }, 1000);
                  } else {
                    checkConnectionStatus(connectionId, false);
                  }
                } else {
                  handleFailResponse("Unable to reconnect.");
                  refetch();
                }
              })
              .catch((error) => {
                handleFailResponse(error?.data?.errors?.[0]?.detail);
              });
          }
        })
        .catch((error) => {
          handleFailResponse(error?.data?.errors?.[0]?.detail);
        });
    }
  };

  const crm = categories.find((c) => c.category === "crm");
  const psa = categories.find((c) => c.category === "psa");
  const product = categories.find((c) => c.category === "product");
  const signature = categories.find((c) => c.category === "esignature");
  const fileStorage = categories.find((c) => c.category === "file-storage");

  const getRootDirectoryField = () => {
    return (
      <>
        <div className="text20">Storage Directory</div>
        <DataTable
          data={[{ id: -1, root: selectedFolder?.name || "" }]}
          columns={[
            {
              header: "Directory Path",
              cell(props) {
                return (
                  <div className="rootDirectory">
                    <div
                      className="changeRoot"
                      onClick={() => setShowFileTreeModal(true)}
                    >
                      Change
                    </div>
                    {props.row.original.root}
                  </div>
                );
              },
            },
          ]}
        />
      </>
    );
  };

  const getCategoryOptions = (category) => {
    const _category = categories.find((c) => c.category === category.category);

    if (!_category || _category.fields.length === 0) return <></>;

    if (_category.category === "file-storage") {
      return getRootDirectoryField();
    }
    return (
      <div className="integrationOptions">
        <div className="text20">
          {normalizeCasing(_category.category)} Integration Options
        </div>
        <Form.Group>
          {_category.fields.map((field, i) => {
            return field.input === "checkbox" ? (
              <div key={i} className="integrationSelect">
                <FormCheck
                  required={false}
                  id={field.label}
                  inline
                  key={field.label}
                  checked={field.value === "1"}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    const data = categories.map((category) => {
                      if (category.category === _category.category) {
                        const updated = category.fields.map((f) =>
                          f.label === field.label
                            ? { ...f, value: checked ? "1" : "0" }
                            : f
                        );
                        return { ...category, fields: updated };
                      } else {
                        return category;
                      }
                    });
                    setCategories(data);
                    handleUpdateFeatures(data);
                  }}
                />
                <div className="selectInfo">
                  <div>{field.label}</div>
                  {field?.help && (
                    <span className="helpText">{field.help}</span>
                  )}
                </div>
              </div>
            ) : (
              <div key={i} className="integrationField">
                <Form.Label>{field.label}</Form.Label>
                <Form.Control
                  type={field.input}
                  value={field.value}
                  onChange={(e) => {
                    if (e.target.value === field.value) return;
                    const data = categories.map((category) => {
                      if (category.category === _category.category) {
                        const updated = category.fields.map((f) =>
                          f.label === field.label
                            ? { ...f, value: e.target.value }
                            : f
                        );
                        return { ...category, fields: updated };
                      } else {
                        return category;
                      }
                    });
                    setCategories(data);

                    // Clear the timer if it exists
                    if (timer) clearTimeout(timer);

                    // Start the timer for delayed trigger
                    setTimer(
                      setTimeout(() => {
                        if (!updateTriggered) {
                          handleUpdateFeatures(data);
                          setUpdateTriggered(true);
                        }
                      }, 3000)
                    );
                  }}
                  onBlur={(e) => {
                    // If onBlur happens, clear the timer and trigger immediately
                    if (timer) clearTimeout(timer);

                    const source = field.path.split(".").slice(1, 2)[0];
                    const attribute = field.path.split(".").pop() as string;
                    // Call handleUpdateFeatures only if it's not already triggered by the onChange event and value is different than what is saved
                    if (
                      !updateTriggered &&
                      e.target.value !== connection?.[source]?.[attribute]
                    ) {
                      handleUpdateFeatures(categories);
                    }
                    // Reset updateTriggered
                    setUpdateTriggered(false);
                  }}
                />
                {field?.help && <span className="helpText">{field.help}</span>}
              </div>
            );
          })}
        </Form.Group>
      </div>
    );
  };

  const handleUpdateFeatures = (categories) => {
    if (!connection?.id) {
      handleFailResponse("");
      return;
    }

    let data = {
      id: connection.id,
      type: "account-connections",
      attributes: {
        settings: { ...connection?.settings },
      },
    };

    categories.forEach((c) => {
      data.attributes[c.category.toLowerCase()] = c.checked;
      if (c.fields.length) {
        c.fields.forEach((field) => {
          const source = field.path.split(".").slice(1, 2)[0]; // "credentials" | "settings" | "mappings"
          const attribute = field.path.split(".").pop() as string;
          if (!data.attributes[source]) {
            data.attributes[source] = { [attribute]: field.value };
          } else {
            data.attributes[source] = {
              ...data.attributes[source],
              [attribute]: field.value,
            };
          }
        });
      }
    });

    updateConnection({
      slug: accountSlug,
      id: connection?.id,
      body: { data },
    })
      .unwrap()
      .then((res) => {
        if (res?.data?.id) {
          setSuccessMessage("Integration features updated.");
          setShowSuccessAlert(true);
          refetch();
        }
      })
      .catch((error) => {
        handleFailResponse(error?.data?.errors?.[0]?.detail);
      });
  };

  const onUseFolder = () => {
    if (selectedFolder?.id && connection?.id) {
      const data = {
        id: connection?.id,
        type: "account-connections",
        attributes: {
          settings: {
            ...connection?.settings,
            root_folder_id: selectedFolder.id == "0" ? null : selectedFolder.id,
          },
        },
      };

      updateConnection({
        slug: accountSlug,
        id: connection?.id,
        //@ts-ignore
        body: { data },
      })
        .unwrap()
        .then((res) => {
          if (res?.data?.id) {
            setSuccessMessage("Root folder updated");
            setShowSuccessAlert(true);
          }
        })
        .catch((error) => {
          handleFailResponse(error?.data?.errors?.[0]?.detail);
        });
    }
  };

  return (
    <div data-testid="connectionsPage" className="connectionsPage manage">
      <div
        data-testid={"manageConnectionPage"}
        className="manageConnectionPage"
      >
        {handleAlert(
          showFailAlert,
          errorMessage,
          setShowFailAlert,
          "warning",
          ToastAlert
        )}
        {handleAlert(
          showSuccessAlert,
          successMessage,
          setShowSuccessAlert,
          "success",
          ToastAlert
        )}
        <div className="connectionsHeader">
          <div className="info">
            <div className="logoBox spaceRight">
              <img className="smallLogoImg" src={connectedApp.logo} />
            </div>
            <div>
              <h3 className="text24 appName">{connectionName}</h3>
              <div className="summary headerSummary">
                {connectedApp.summary}
              </div>
              {connectedApp?.helpUrl && (
                <a
                  href={connectedApp.helpUrl}
                  target="_blank"
                  className="setupLink"
                >
                  How do I set up {connectionName}?
                </a>
              )}
            </div>
          </div>
          <div className="flexCenter">
            <Button
              onClick={() => navigate("/admin/account_connections")}
              className="ssButtonBgWhite spaceRight"
            >
              Back
            </Button>
            {connection?.status === "Disabled" ? (
              <Button
                onClick={() => {
                  let changesToCredentials = false;
                  fields.forEach((field) => {
                    const source = field.path.split(".").slice(1, 2)[0]; // "credentials" | "settings" | "mappings"
                    const attribute = field.path.split(".").pop() as string;
                    if (connection?.[source]?.[attribute] !== field.value) {
                      changesToCredentials = true;
                    }
                  });

                  //IF any changes to credentials, then reconnect, otherwise enable
                  if (changesToCredentials) {
                    setActivating(true);
                    handleEnable(true);
                  } else {
                    handleEnable(false);
                  }
                }}
                className="squareGreenButton"
              >
                Enable
              </Button>
            ) : (
              <>
                <Button
                  onClick={
                    connection?.status === "Connected"
                      ? () => handleDisable()
                      : () => {
                          checkConnectionStatusRef.current = true;
                          setActivating(true);
                          handleEnable(true);
                        }
                  }
                  className="dangerButton"
                >
                  {connection?.status === "Connected" ? "Disable" : "Reconnect"}
                </Button>
              </>
            )}
          </div>
        </div>
        <hr></hr>
        {connection && (
          <>
            <h3 className="text24">Connection Status</h3>
            <DataTable
              data={[connection]}
              columns={
                isFileStorage
                  ? [...connectionsTableColumns].slice(0, -1)
                  : connectionsTableColumns
              }
              bordered
              hover
            />
          </>
        )}
        <h3 className="text24 configHeader">{connectionName} Configuration</h3>
        <Tabs
          activeKey={currentTab}
          className="mb-3"
          onSelect={(eventKey) => setCurrentTab(Number(eventKey))}
        >
          <Tab
            eventKey={1}
            title={"Connection"}
            tabClassName="connectionTab"
            className="connectionContent"
          >
            {isConnected && (
              <div className="disablePrompt">
                <strong>Please Note: </strong> To edit Connection Options,
                please <strong>disable</strong> the connection.
              </div>
            )}
            <div className="text20">Connection Options</div>
            <Form>
              <Row className="authFields">
                {fields.map((field, i) => buildConnectedAppForm(field, i))}
              </Row>
            </Form>
          </Tab>
          <Tab
            eventKey={2}
            title={"Additional Features"}
            tabClassName="connectionTab"
            className="connectionContent"
          >
            <div className="text20">Integration Features</div>
            <div className="integrationFeatures">
              <div className="flexCenter">
                {categories.map((option, i) => {
                  return (
                    <div
                      key={i}
                      className={`integrationOption ${
                        option.checked ? "active" : ""
                      }`}
                    >
                      <FormCheck
                        required={false}
                        id={option.category}
                        className="option"
                        inline
                        key={option.category}
                        checked={option.checked}
                        onChange={(e) => {
                          const updated = categories.map((c) =>
                            c.category == e.target.id
                              ? { ...c, checked: !c.checked }
                              : c
                          );
                          setCategories(updated);
                          handleUpdateFeatures(updated);
                        }}
                      />{" "}
                      {normalizeCasing(option.category)}
                    </div>
                  );
                })}
              </div>
            </div>
            {crm?.checked && getCategoryOptions(crm)}
            {psa?.checked && getCategoryOptions(psa)}
            {product?.checked && getCategoryOptions(product)}
            {signature?.checked && getCategoryOptions(signature)}
            {fileStorage?.checked && getCategoryOptions(fileStorage)}
          </Tab>
          {user?.data?.attributes["site-admin"] && (
            <Tab
              eventKey={3}
              title={"Integration Activity"}
              tabClassName="connectionTab"
              className="connectionContent"
            >
              <div className="text20 sectionSpaceBelow">
                Integration Activity
              </div>
              <DataTable
                data={
                  integrationLogs.length < 1
                    ? [
                        {
                          id: 0,
                          time: "No integration activity found",
                        },
                      ]
                    : integrationLogs
                }
                columns={integrationActivityTableColumns}
                bordered
                hover
                striped
                totalPages={pageMeta.pageCount}
                totalRows={pageMeta.recordCount}
                currentPage={pageNumber}
                setCurrentPage={setPageNumber}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
                paginationEnabled
                pageSizeEnabled
              />
            </Tab>
          )}
        </Tabs>
      </div>
      {/* Success Alert modal */}
      <ScopeStackModal
        modalTitle="Configure Integration"
        modalBody={
          <div className="activateAlertModal">
            <FontAwesomeIcon icon={faCheckCircle} className="activateSuccess" />{" "}
            This integration has connected successfully!
          </div>
        }
        button1Text={undefined}
        handleButton1Click={() => null}
        button2Text={undefined}
        handleButton2Click={() => null}
        show={showSuccessModal}
        handleClose={() => {
          refetch();
          setShowSuccessModal(false);
        }}
      />
      {/* Error Alert modal */}
      <ScopeStackModal
        modalTitle="Configure Integration"
        modalBody={
          <div className="activateAlertModal">
            <FontAwesomeIcon icon={faCircleXmark} className="activateError" />{" "}
            {errorMessage}
          </div>
        }
        button1Text={undefined}
        handleButton1Click={() => null}
        button2Text={undefined}
        handleButton2Click={() => null}
        show={showErrorModal}
        handleClose={() => {
          refetch();
          setShowErrorModal(false);
        }}
      />
      {/* Loading Modal */}
      <ScopeStackModal
        modalTitle="Configure Integration"
        modalBody={<SmallSpinner />}
        button1Text={undefined}
        handleButton1Click={() => null}
        button2Text={undefined}
        handleButton2Click={() => null}
        show={activating}
        handleClose={() => {
          checkConnectionStatusRef.current = false;
          setActivating(false);
        }}
      />
      {connection && connectedApp ? (
        <FolderTreeComponentModal
          showCheckbox={false}
          readOnly={true}
          indentPixels={40}
          account_slug={accountSlug}
          authorizationCode={authorizationCode}
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
          showFileTreeModal={showFileTreeModal}
          setShowFileTreeModal={setShowFileTreeModal}
          folderLoading={folderLoading}
          setFolderLoading={setFolderLoading}
          selectedConnection={selectedConnection}
          setSelectedConnection={setSelectedConnection}
          selectedIntegration={selectedIntegration}
          setSelectedIntegration={setSelectedIntegration}
          storageOptions={null}
          canCreateFolder={false}
          canEditFolderName={false}
          onUseFolder={onUseFolder}
          isChooseRootFolder={true}
        />
      ) : (
        <SmallSpinner />
      )}
    </div>
  );
};

export default Manage;
