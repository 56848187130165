import { V1SurveyRecommendationResource } from "@generated";
import { Survey } from "../types";
import { formatResource } from "../../Projects/ServicesV2/common";
import API from "../../../utils/API/API";

const applySurvey = async (
  recommendations: Survey["recommendations"],
  surveyId: number,
  projectId: string,
  account: { code: string; slug: string },
  handleFailResponse: (msg: string) => void,
  fromProjectServices: boolean
) => {
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  if (!recommendations?.length) {
    handleFailResponse("No recommended services to apply");
    return;
  }

  try {
    for (const recommendation of recommendations) {
      if (recommendation.id) {
        const data: V1SurveyRecommendationResource = {
          id: Number(recommendation?.id),
          type: "survey-recommendations",
          attributes: {
            quantity: recommendation?.quantity,
            refinements: recommendation?.refinements?.map((r) => {
              return {
                item_id: r.id,
                quantity: Number(r.qty || 1),
                project_resource_id:
                  r?.resource?.id || recommendation?.resource?.id,
              };
            }),
          },
        };

        if (recommendation?.resource) {
          data.relationships = {
            "project-resource": formatResource(recommendation),
          };
        }

        // //Update survey recommendations
        const updated: { data?: any; error?: any } = await API.Patch(
          `${apiHost}/${account.slug}/v1/survey-recommendations/${recommendation.id}`,
          { data },
          account.code
        );

        if (updated?.error)
          handleFailResponse(
            "Changes were unable to be saved to one or more recommendations"
          );
      } else return;
    }

    //Apply survey
    const applied = await API.Put(
      `${apiHost}/${account.slug}/v1/surveys/${surveyId}/apply`,
      {},
      account.code
    );

    if (applied?.status === 200) {
      const path = fromProjectServices ? "project_editor" : "project_surveys";
      window.location.replace(`${appHost}/projects/${projectId}/${path}`);
    } else {
      handleFailResponse(
        "Something went wrong applying survey recommendations to this project"
      );
    }
  } catch (err) {
    handleFailResponse("");
  }
};

export default applySurvey;
