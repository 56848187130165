import VendorInfo from "../Common";
import { useState } from "react";
import { Vendor } from "../Common/types";

const Create = () => {
  const [vendor, setVendor] = useState<Vendor>({
    id: 0,
    type: "vendors",
    attributes: {
      name: "",
      streetAddress: "",
      street2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    },
    relationships: {
      account: null,
    },
  });

  const onSubmit = () => {};

  return (
    <VendorInfo
      vendor={vendor}
      setVendorAttributes={setVendor}
      mode="create"
      onSubmit={onSubmit}
    />
  );
};

export default Create;
